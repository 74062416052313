import { defineStore } from 'pinia';

interface NotificacaoAtiva {
	id_notificacao_possivel: number;
	notificacao_possivel: string;
	ativo: number;
	variavel: string;
}
interface State {
	idUsuario: null | number;
	login: string;
	password: string;
	nome: string;
	sobrenome: string;
	email: string;
	id_cargo: number;
	id_grupo: number;
	id_equipe: number;
	id_empresa: number;
	grupo: string;
	equipe: string;
	empresa: string;
	cargo: string;
	acessos: any[];
	errorLogin: boolean;
	errorText: string;
	clickButton: boolean;
	loginAction: boolean;
	haveAError: boolean;
	signature: string;
	signatureModalOpen: boolean;
	loadNewSignature: boolean;
	clickButtonUpdateSignature: boolean;
	passwordUpdateSignature: string;
	errorUpdateSignature: boolean;
	errorUpdateSignatureText: string;
	modalNotificationOpen: boolean;
	notificacoesAtivas: NotificacaoAtiva[];
	modalDesvioPagamento: boolean;
	modalNotificacoes: boolean;
	errorDesvioPagamento: boolean;
	errorTextDesvioPagamento: string;
	clickedButtonDesvioPagamento: boolean;
	infosSearch: boolean;
}

export const useLogin = defineStore('login', {
	state: (): State => ({
		idUsuario: null,
		login: '',
		password: '',
		nome: '',
		sobrenome: '',
		email: '',
		id_cargo: 0,
		id_grupo: 0,
		id_equipe: 0,
		id_empresa: 0,
		grupo: '',
		equipe: '',
		empresa: '',
		cargo: '',
		acessos: [] as any,
		errorLogin: false,
		errorText: '',
		clickButton: false,
		loginAction: false,
		haveAError: false,
		signature: '',
		signatureModalOpen: false,
		loadNewSignature: false,
		clickButtonUpdateSignature: false,
		passwordUpdateSignature: '',
		errorUpdateSignature: false,
		errorUpdateSignatureText: '',
		modalNotificationOpen: false,
		notificacoesAtivas: [],
		modalDesvioPagamento: false,
		errorDesvioPagamento: false,
		errorTextDesvioPagamento: '',
		clickedButtonDesvioPagamento: false,
		modalNotificacoes: false,
		infosSearch: false
	})
});
