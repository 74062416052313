<script setup lang="ts">
import { useHeaderInfos } from '../../../stores/headerInfos';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';
import { useLogin } from '../../../stores/login';
const infoLogin = useLogin();
const infoHeader = useHeaderInfos();
const { abertoMenuStart } = storeToRefs(infoHeader);
import { OnClickOutside } from '@vueuse/components';
const router = useRouter();
const clickOutside = () => {
	infoHeader.onClickOutsideMenu();
};
const minhasInformacoes = () => {
	router.push({ name: 'meuPerfil' });
};
const alterarSenha = () => {
	router.push({ name: 'alterarSenha' });
};
const sairApp = async () => {
	const registrations = await navigator.serviceWorker.getRegistrations();

	for (const registration of registrations) {
		if (registration.active?.scriptURL === `${import.meta.env.VITE_SERVER}/firebase-messaging-sw.js`) {
			await registration.unregister();
			console.log('Service worker removido com sucesso.');
			break;
		}
	}
	router.push('/').then((res) => {
		infoLogin.$reset();
		var allCookies = document.cookie.split(';');
		for (var i = 0; i < allCookies.length; i++) document.cookie = allCookies[i] + '=;expires=' + new Date(0).toUTCString();
	});
};
</script>

<template>
	<Transition :duration="550" name="bg">
		<div class="outer" v-if="abertoMenuStart">
			<OnClickOutside @trigger="clickOutside()" class="clickOutsideDesk">
				<div class="inner">
					<div class="conteudo">
						<div class="pessoal">
							<div class="minhasInformacoes" @click="minhasInformacoes">
								<h1>Meu perfil</h1>
							</div>
							<div class="alterarSenha" @click="alterarSenha">
								<h1>Alterar senha</h1>
							</div>
						</div>
						<div class="track300"></div>
						<div class="destrutivoDeskMenu" @click="sairApp">
							<div class="sairDesk">
								<p>Sair</p>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M15 20H10.1436H7V4H15" stroke="#B9202B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
									<path d="M12 12H19M19 12L16.375 9M19 12L16.375 15" stroke="#B9202B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
								</svg>
							</div>
						</div>
					</div>
				</div>
			</OnClickOutside>
		</div>
	</Transition>
</template>
