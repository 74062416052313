<script setup lang="ts">
import { vMaska } from 'maska';
import { useLogin } from '../stores/login';
import { useForgotPassword } from '../stores/forgotPassword';
import { useChangePassword } from '../stores/changePassword';
import { usePricing } from '../stores/pricing';
import { useFilters } from '../stores/filters';
import { useGroupTeamControl } from '../stores/groupTeamControl';
import { useControlUser } from '../stores/userControl';
import { vehicle } from '../stores/vehicle';
import { useEvaluation } from '../stores/evaluation';
import { useEvaluationView } from '../stores/evaluationView';
import { useRequestEvaluation } from '../stores/requestEvaluation';
import { useCompanyControl } from '../stores/companyControl';
import { storeToRefs } from 'pinia';
import { watch, computed, ref } from 'vue';
const loginStore = useLogin();
const requestEvalution = useRequestEvaluation();
const forgotPassword = useForgotPassword();
const changePassword = useChangePassword();
const controlUser = useControlUser();
const controlGroupTeam = useGroupTeamControl();
const infosVehicle = vehicle();
const newEvaluation = useEvaluation();
const pricing = usePricing();
const filters = useFilters();
const viewEvaluation = useEvaluationView();
const companyControl = useCompanyControl();
const { placaFilter } = storeToRefs(filters);
const { newUserEmail, newUserName, newUserSurname, newUserPassword, errorTextUserControl, editUserEmail, editUserName, editUserSurname, deleteUserPassword } = storeToRefs(controlUser);
const { emailForgot, errorDesc, newPasswordForgot, confirmPasswordForgot } = storeToRefs(forgotPassword);
const { login, password, errorText, loginAction, passwordUpdateSignature, notificacoesAtivas, acessos } = storeToRefs(loginStore);
const { senhaAntiga, novaSenha, confirmSenha, showPassword, errorChangePassword, errorTextChangePassword } = storeToRefs(changePassword);
const { editGroupName, editTeamName, editTeam, errorTextGroupTeamControl, deleteGroupPassword, newGroupName, newTeamGroup } = storeToRefs(controlGroupTeam);
const { placa, placaError, placaErrorText, renavam, anoFabricacao, anoFabricacaoError, anoFabricacaoErrorText, anoModelo, renavamError, renavamErrorText } = storeToRefs(infosVehicle);
const { km, documentExercise, documentExerciseError, documentExerciseErrorText, kmRevisao, kmError, kmErrorText, kmRevisaoError, kmRevisaoErrorText } = storeToRefs(newEvaluation);
const { kmMaximaRepasse, margemNegociacao, margemMaxima, margemMinima, lucro, lucroMinimo, lucroMaximo, higienizacaoCusto, polimentoCusto, tempoEstoqueMaximo, tempoEstoqueMinimo, acrescimoParaReparo, depreciacaoCapitalValue, carenciaFloorplan, percentualFipeCustosRevisao, aliquotaIpva, aliquotaIcms, aliquotaPisCofins, lucroRepassador, lucroRepassadorMaximo, lucroRepassadorMinimo } = storeToRefs(pricing);
const { placaSolicita, emailClienteSolicita, nomeClienteSolicita, telefoneClienteSolicita, documentExerciseSolicita, revisaoFabricaKm } = storeToRefs(requestEvalution);
const { modalEditaValor, tipoEdita, tituloEdita, editValorFinal, valorTempoVendaNovo, valorHigienizacaoEsteticaNovo, valorLucroNovo, valorMargemNegociacaoNovo, valorMediaInternetNovo, valorProvisaoRevisoesNovo, valorReparosNovo } = storeToRefs(viewEvaluation);
const { refreshListCompany, modalAddCompany, modalEditCompany, companyId, editCompanyName, clickButtonCompanyControl, editAvaliacoes_mensais, companyAdminName, companyAdminPassword, companyAdminSurname, companyGroupName, companyName, companyTeamName, companyUf, companyAdminEmail, companyAvaliacoesMensais } = storeToRefs(companyControl);
const loginActionTrigger = (type: string) => {
	loginAction.value = true;
};
const acessosAtivos = ref<any>([]);
acessos.value.forEach((acesso: any) => {
	if (acesso.ativo == 1) {
		acessosAtivos.value.push(acesso);
	}
});
const verificaAcesso = (acesso: number): boolean => {
	let acessoAlvo = acesso;
	return acessosAtivos.value.some((acesso: any) => [acessoAlvo].includes(acesso.id_acesso));
};
const handleInputKm = (event: any) => {
	if (event.inputType == 'insertText') {
		km.value = km.value.replace(/\D/g, '');
		const cleanedValue = km.value.replace(/\D/g, '');
		if (cleanedValue !== '') {
			km.value = parseInt(cleanedValue).toLocaleString('pt-BR');
		}
	}
};
const handleInputKmRevisao = (event: any) => {
	if (event.inputType == 'insertText') {
		kmRevisao.value = kmRevisao.value.replace(/\D/g, '');
		const cleanedValue = kmRevisao.value.replace(/\D/g, '');
		if (cleanedValue !== '') {
			kmRevisao.value = parseInt(cleanedValue).toLocaleString('pt-BR');
		}
	}
};
const handleInputKmRevisaoCliente = (event: any) => {
	if (event.inputType == 'insertText') {
		revisaoFabricaKm.value = revisaoFabricaKm.value.replace(/\D/g, '');
		const cleanedValue = revisaoFabricaKm.value.replace(/\D/g, '');
		if (cleanedValue !== '') {
			revisaoFabricaKm.value = parseInt(cleanedValue).toLocaleString('pt-BR');
		}
	}
};
const handleInputKmRepasse = (event: any) => {
	if (event.inputType == 'insertText') {
		kmMaximaRepasse.value = kmMaximaRepasse.value.replace(/\D/g, '');
		const cleanedValue = kmMaximaRepasse.value.replace(/\D/g, '');
		if (cleanedValue !== '') {
			kmMaximaRepasse.value = parseInt(cleanedValue).toLocaleString('pt-BR');
		}
	}
};
const addSuffixKm = () => {
	if (km.value !== '' && !km.value.endsWith('km')) {
		km.value += ' km';
	}
};
const addSuffixKmRevisao = () => {
	if (kmRevisao.value !== '' && !kmRevisao.value.endsWith('km')) {
		kmRevisao.value += ' km';
	}
};
const addSuffixKmRevisaoCliente = () => {
	if (revisaoFabricaKm.value !== '' && !revisaoFabricaKm.value.endsWith('km')) {
		revisaoFabricaKm.value += ' km';
	}
};
const addSuffixKmRepasse = () => {
	if (kmMaximaRepasse.value !== '' && !kmMaximaRepasse.value?.endsWith('km')) {
		kmMaximaRepasse.value += ' km';
	}
};
watch(documentExercise, (newExercise) => {
	if (parseInt(newExercise) < parseInt(anoFabricacao.value) - 2) {
		documentExerciseErrorText.value = 'Exercicio do documento inválido';
		documentExerciseError.value = true;
	} else if (parseInt(newExercise) > new Date().getFullYear()) {
		documentExerciseErrorText.value = 'Exercicio do documento inválido';
		documentExerciseError.value = true;
	} else {
		documentExerciseErrorText.value = '';
		documentExerciseError.value = false;
	}
});
watch([() => anoFabricacao.value, () => anoModelo.value], ([newVal1, newVal2], [oldVal1, oldVal2]) => {
	if (newVal2.length > 0) {
		if (parseInt(newVal1) < parseInt(newVal2) - 1) {
			anoFabricacaoErrorText.value = 'Ano fabricação inválido';
			anoFabricacaoError.value = true;
		} else if (parseInt(newVal1) > parseInt(newVal2) + 1) {
			anoFabricacaoErrorText.value = `Ano fabricação inválido`;
			anoFabricacaoError.value = true;
		} else {
			anoFabricacaoErrorText.value = '';
			anoFabricacaoError.value = false;
		}
	}
});

watch(placa, (newPlaca) => {
	if (newPlaca.length !== 8) {
		placaErrorText.value = 'Placa necessita ter 8 caracteres';
		placaError.value = true;
	} else {
		placaErrorText.value = '';
		placaError.value = false;
	}
});
watch(renavam, (newRenavam) => {
	if (newRenavam.length == 0) {
		renavamErrorText.value = '';
		renavamError.value = false;
	} else if (newRenavam.length < 9 || newRenavam.length > 11) {
		renavamErrorText.value = 'Renavam necessita ter entre 9 e 11 caracteres';
		renavamError.value = true;
	} else {
		renavamErrorText.value = '';
		renavamError.value = false;
	}
});
watch(km, (newKm) => {
	const kmNumerico = parseInt(newKm.replace('km', '').replace(/\./g, ''));
	if (kmNumerico <= 1) {
		kmErrorText.value = 'Km precisa ser maior que 1';
		kmError.value = true;
	} else {
		kmErrorText.value = '';
		kmError.value = false;
	}
});
watch(
	[margemMaxima, margemMinima],
	([newMax, newMin]) => {
		const numericMax = newMax.replace(/\D/g, '');
		const numericMin = newMin.replace(/\D/g, '');

		if (numericMax && parseFloat(numericMax) > 1000000) {
			margemMaxima.value = '999999';
		}

		if (numericMin && parseFloat(numericMin) > 1000000) {
			margemMinima.value = '999999';
		}
	},
	{ immediate: true }
);

watch(
	[lucroMaximo, lucroMinimo],
	([newMax, newMin]) => {
		const numericMax = newMax.replace(/\D/g, '');
		const numericMin = newMin.replace(/\D/g, '');

		if (numericMax && parseFloat(numericMax) > 1000000) {
			lucroMaximo.value = '999999';
		}

		if (numericMin && parseFloat(numericMin) > 1000000) {
			lucroMinimo.value = '999999';
		}
	},
	{ immediate: true }
);

watch(
	[higienizacaoCusto, polimentoCusto],
	([newHigienizacao, newPolimento]) => {
		const numericCostHigienizacao = newHigienizacao.replace(/\D/g, '');
		const numericCostPolimento = newPolimento.replace(/\D/g, '');

		if (numericCostHigienizacao && parseFloat(numericCostHigienizacao) > 1000000) {
			higienizacaoCusto.value = '999999';
		}

		if (numericCostPolimento && parseFloat(numericCostPolimento) > 1000000) {
			polimentoCusto.value = '999999';
		}
	},
	{ immediate: true }
);

watch(
	[tempoEstoqueMaximo, tempoEstoqueMinimo, acrescimoParaReparo, carenciaFloorplan],
	([newMax, newMin, acrescimoParaReparoVal, carenciaFloorplanVal]) => {
		const numericMax = newMax.replace(/\D/g, '');
		const numericMin = newMin.replace(/\D/g, '');
		const numericAcrescimoParaReparo = acrescimoParaReparoVal.replace(/\D/g, '');
		const numericCarenciaFloorplan = carenciaFloorplanVal.replace(/\D/g, '');

		if (numericMax && parseFloat(numericMax) > 1000) {
			tempoEstoqueMaximo.value = '1000';
		}

		if (numericMin && parseFloat(numericMin) > 1000) {
			tempoEstoqueMinimo.value = '1000';
		}

		if (numericAcrescimoParaReparo && parseFloat(numericAcrescimoParaReparo) > 1000) {
			acrescimoParaReparo.value = '1000';
		}
		if (numericCarenciaFloorplan && parseFloat(numericCarenciaFloorplan) > 1000) {
			carenciaFloorplan.value = '1000';
		}
	},
	{ immediate: true }
);

watch(kmRevisao, (newKmRevisao) => {
	const kmRevisaoNumerico = parseInt(newKmRevisao.replace('km', '').replace(/\./g, ''));
	const kmNumerico = parseInt(km.value.replace('km', '').replace(/\./g, ''));
	if (kmRevisaoNumerico > kmNumerico) {
		kmRevisaoErrorText.value = 'Km ultima revisão tem que ser menor ou igual ao km atual';
		kmRevisaoError.value = true;
	} else {
		kmRevisaoErrorText.value = '';
		kmRevisaoError.value = false;
	}
});
const editaValorFinal = () => {
	modalEditaValor.value = true;
	tipoEdita.value = 'valorFinal';
	tituloEdita.value = 'Edita valor final';
};
const index = notificacoesAtivas.value.findIndex((item) => item.id_notificacao_possivel === 4);
defineProps<{
	placeholder?: string;
	type?: string;
	error?: boolean;
	disabled?: boolean;
	border?: boolean;
	valorFinalEdita?: boolean;
}>();
</script>

<template>
	<div class="inputGroup">
		<div class="label" :class="{ labelInfo: type == 'info', labelInfoPrecificacao: type == 'infoPrecificacao' }">
			<h1>
				<slot name="label"></slot>
			</h1>
			<p>
				<slot name="auxiliarText"></slot>
			</p>
		</div>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && (errorText == 'Usuário não cadastrado.' || errorText == 'Formato de e-mail inválido' || errorText == 'E-mail ou senha não correspondem'),
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="login"
			v-if="type == 'login'"
			@input="
				login = login
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replace(/\s/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: error && disabled == false && errorText !== 'Usuário não cadastrado.' && errorText !== 'Login não informado.' && errorText !== 'Formato de email inválido.',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="password"
			v-if="type == 'password'"
			@input="
				password = password
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
			@keyup.enter="loginActionTrigger(type)"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorDesc == 'E-mail inválido') || errorDesc == 'Email não encontrado',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="emailForgot"
			v-if="type == 'email'"
			@input="
				emailForgot = emailForgot
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replace(/\s/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="@@@-#*##"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="placa"
			v-if="type == 'placaCarro'"
			@input="placa = placa.toUpperCase()"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="@@@-#*##"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="placaSolicita"
			v-if="type == 'placaSolicita'"
			@input="placaSolicita = placaSolicita.toUpperCase()"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="@@@-#*##"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="placaFilter"
			v-if="type == 'placaFilter'"
			@input="placaFilter = placaFilter.toUpperCase()"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			@input="handleInputKm"
			@blur="addSuffixKm"
			v-model="km"
			v-if="type == 'km'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			@input="handleInputKmRevisao"
			@blur="addSuffixKmRevisao"
			v-model="kmRevisao"
			v-if="type == 'kmRevisao'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			@input="handleInputKmRevisaoCliente"
			@blur="addSuffixKmRevisaoCliente"
			v-model="revisaoFabricaKm"
			v-if="type == 'kmRevisaoCliente'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="margemNegociacao"
			v-if="type == 'margemNegociacao'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucro"
			v-if="type == 'lucro'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="depreciacaoCapitalValue"
			v-if="type == 'depreciacaoCapitalValue'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="percentualFipeCustosRevisao"
			v-if="type == 'percentualFipeCustosRevisao'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="aliquotaIpva"
			v-if="type == 'aliquotaIpva'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="aliquotaIcms"
			v-if="type == 'aliquotaIcms'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="aliquotaPisCofins"
			v-if="type == 'aliquotaPisCofins'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucroRepassador"
			v-if="type == 'lucroRepassador'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucroRepassadorMinimo"
			v-if="type == 'lucroRepassadorMinimo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucroRepassadorMaximo"
			v-if="type == 'lucroRepassadorMaximo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucroMinimo"
			v-if="type == 'lucroMinimo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="lucroMaximo"
			v-if="type == 'lucroMaximo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="margemMinima"
			v-if="type == 'margemMinima'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="margemMaxima"
			v-if="type == 'margemMaxima'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="higienizacaoCusto"
			v-if="type == 'higienizacaoCusto'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="polimentoCusto"
			v-if="type == 'polimentoCusto'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# dias','## dias', '### dias', '#### dias']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="tempoEstoqueMaximo"
			v-if="type == 'tempoEstoqueMaximo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# dias','## dias', '### dias', '#### dias']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="tempoEstoqueMinimo"
			v-if="type == 'tempoEstoqueMinimo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# dias','## dias', '### dias', '#### dias']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="acrescimoParaReparo"
			v-if="type == 'acrescimoParaReparo'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# dias','## dias', '### dias', '#### dias']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="carenciaFloorplan"
			v-if="type == 'carenciaFloorplan'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="####"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="documentExercise"
			v-if="type == 'exercicioDocumento'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="####"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="documentExerciseSolicita"
			v-if="type == 'exercicioDocumentoSolicita'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="###########"
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="renavam"
			v-if="type == 'renavam'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="####"
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="anoFabricacao"
			v-if="type == 'anoFabricacao'"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: (error && disabled == false && errorTextUserControl == 'Senha não informada') || errorTextUserControl == 'Senha incorreta',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="deleteUserPassword"
			v-if="type == 'deleteUserPassword'"
			@input="
				deleteUserPassword = deleteUserPassword
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="passwordUpdateSignature"
			v-if="type == 'passwordUpdateSignature'"
			@input="
				passwordUpdateSignature = passwordUpdateSignature
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: (error && disabled == false && errorTextGroupTeamControl == 'Senha não informada') || errorTextGroupTeamControl == 'Senha incorreta',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="deleteGroupPassword"
			v-if="type == 'deleteGroupPassword'"
			@input="
				deleteGroupPassword = deleteGroupPassword
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextUserControl == 'Formato de email inválido.') || errorTextUserControl == 'Email não encontrado.' || errorTextUserControl == 'Email não informado.' || errorTextUserControl == 'Usuário já cadastrado.',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-if="type == 'editUserEmail'"
			@input="
				editUserEmail = editUserEmail
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replace(/\s/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextGroupTeamControl == 'Nome da equipe não informado') || errorTextGroupTeamControl == 'Nome da equipe já existente na empresa' || errorTextGroupTeamControl == 'Nome novo da equipe igual ao antigo' || errorTextGroupTeamControl == 'Nome da equipe já existe nesta equipe',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editTeamName"
			v-if="type == 'editTeamName'"
			@input="editTeamName = editTeamName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextGroupTeamControl == 'Nome do grupo não informado') || errorTextGroupTeamControl == 'Grupo não informado' || errorTextGroupTeamControl == 'já existe um grupo com o mesmo nome na equipe selecionada' || errorTextGroupTeamControl == 'Nome do grupo já existe nessa equipe' || errorTextGroupTeamControl == 'Nome novo do grupo igual ao antigo',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editGroupName"
			v-if="type == 'editGroupName'"
			@input="editGroupName = editGroupName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextGroupTeamControl == 'Nome da equipe não informado') || errorTextGroupTeamControl == 'Nome da equipe já existe nesta equipe',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newTeamGroup"
			v-if="type == 'newTeamGroup'"
			@input="newTeamGroup = newTeamGroup.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextGroupTeamControl == 'Nome do grupo não informado') || errorTextGroupTeamControl == 'Grupo não informado' || errorTextGroupTeamControl == 'já existe um grupo com o mesmo nome na equipe selecionada',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newGroupName"
			v-if="type == 'newGroupName'"
			@input="newGroupName = newGroupName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && errorTextUserControl == 'Nome não informado',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editUserName"
			v-if="type == 'editUserName'"
			@input="editUserName = editUserName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && errorTextUserControl == 'Sobrenome não informado',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editUserSurname"
			v-if="type == 'editUserSurname'"
			@input="editUserSurname = editUserSurname.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: (error && disabled == false && errorTextUserControl == 'Formato de email inválido') || errorTextUserControl == 'E-mail não informado' || errorTextUserControl == 'Email já utilizado em outra conta ativa',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newUserEmail"
			v-if="type == 'newUserEmail'"
			@input="
				newUserEmail = newUserEmail
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && errorTextUserControl == 'Senha não informada ou menor que 6 caracteres',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newUserPassword"
			v-if="type == 'newUserPassword'"
			@input="
				newUserPassword = newUserPassword
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && errorTextUserControl == 'Nome não informado',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newUserName"
			v-if="type == 'newUserName'"
			@input="newUserName = newUserName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false && errorTextUserControl == 'Sobrenome não informado',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newUserSurname"
			v-if="type == 'newUserSurname'"
			@input="newUserSurname = newUserSurname.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="kmMaximaRepasse"
			@input="handleInputKmRepasse"
			@blur="addSuffixKmRepasse"
			v-if="type == 'kmMaximaRepasse'"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: (error && disabled == false && errorDesc == 'Nova senha não informada') || errorDesc == 'Tamanho minimo de senha 6 digítos',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="newPasswordForgot"
			v-if="type == 'newPasswordForgot'"
			@input="
				newPasswordForgot = newPasswordForgot
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			type="password"
			:class="{
				errorInput: error && disabled == false && errorDesc == 'Senhas devem ser iguais',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="confirmPasswordForgot"
			v-if="type == 'confirmPasswordForgot'"
			@input="
				confirmPasswordForgot = confirmPasswordForgot
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
			:disabled="disabled"
		/>
		<input
			:type="showPassword == true ? 'text' : 'password'"
			:class="{
				errorInput: (error && disabled == false && errorTextChangePassword == 'Senha incorreta') || errorTextChangePassword == 'Senha não informada ou menor que 6 caracteres' || errorTextChangePassword == 'Usuário e senha não correspondem',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="senhaAntiga"
			v-if="type == 'senhaAntiga'"
			:disabled="disabled"
			@input="
				senhaAntiga = senhaAntiga
					.replace(/\s+/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
		/>
		<input
			:type="showPassword == true ? 'text' : 'password'"
			:class="{
				errorInput: (error && disabled == false && errorTextChangePassword == 'Nova senha não informada') || errorTextChangePassword == 'Senha não informada ou menor que 6 caracteres' || errorTextChangePassword == 'As senhas devem ser iguais',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="novaSenha"
			v-if="type == 'novaSenha'"
			:disabled="disabled"
			@input="
				novaSenha = novaSenha
					.replace(/\s+/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
		/>
		<input
			:type="showPassword == true ? 'text' : 'password'"
			:class="{
				errorInput: (error && disabled == false && errorTextChangePassword == 'Senhas devem ser iguais') || errorTextChangePassword == 'Senha não informada ou menor que 6 caracteres' || errorTextChangePassword == 'As senhas devem ser iguais',
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="confirmSenha"
			v-if="type == 'confirmSenha'"
			:disabled="disabled"
			@input="
				confirmSenha = confirmSenha
					.replace(/\s+/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
		/>
		<input
			type="text"
			:class="{
				disabledInput: disabled == true,
				inputWithBorder: border == true,
				inputInfo: type == 'info'
			}"
			:disabled="disabled"
			:placeholder="placeholder"
			v-if="type == 'info'"
		/>
		<input
			type="text"
			v-maska
			data-maska="['##,##%', '#,##%']"
			data-maska-eager
			:class="{
				errorInput: error && !disabled,
				disabledInput: disabled,
				inputWithBorder: border
			}"
			:placeholder="placeholder"
			v-model="notificacoesAtivas[index].variavel"
			v-if="type == 'editDesvioPagamento'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['# R$','## R$', '#.## R$', '### R$', '#.### R$', '##.### R$', '###.### R$', '#.###.### R$']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editValorFinal"
			v-if="type == 'editValorFinal'"
			:disabled="disabled"
		/>
		<svg @click="editaValorFinal()" style="position: absolute; cursor: pointer; top: 3px" :style="{ right: placeholder?.length <= 3 ? placeholder?.length * 11 + 'px' : placeholder?.length * 10 + 'px' }" v-if="type == 'infoPrecificacao' && valorFinalEdita == true && placeholder && (verificaAcesso(200) || verificaAcesso(201) || verificaAcesso(202) || verificaAcesso(203))" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M10.8335 5.8335L3.62639 13.0406C3.43885 13.2281 3.3335 13.4825 3.3335 13.7477V15.6668C3.3335 16.2191 3.78121 16.6668 4.3335 16.6668H6.25262C6.51783 16.6668 6.77219 16.5615 6.95972 16.3739L14.1668 9.16683M10.8335 5.8335L14.1668 9.16683M10.8335 5.8335L12.6264 4.0406C13.0169 3.65008 13.6501 3.65008 14.0406 4.0406L15.9597 5.95972C16.3502 6.35025 16.3502 6.98341 15.9597 7.37394L14.1668 9.16683" stroke="#63636E" stroke-width="1.5" stroke-linejoin="round" />
		</svg>

		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorMargemNegociacaoNovo"
			v-if="type == 'editValorMargemNegociacao'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorMediaInternetNovo"
			v-if="type == 'editValorMediaInternet'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorLucroNovo"
			v-if="type == 'editValorLucro'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorReparosNovo"
			v-if="type == 'editValorReparos'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorHigienizacaoEsteticaNovo"
			v-if="type == 'editValorHigienizacao'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '#.##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorProvisaoRevisoesNovo"
			v-if="type == 'editValorProvisaoRevisoes'"
			:disabled="disabled"
		/>
		<input
			type="text"
			v-maska
			data-maska="['#','##', '###']"
			data-maska-eager
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="valorTempoVendaNovo"
			v-if="type == 'editValorTempoVenda'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="nomeClienteSolicita"
			v-if="type == 'nomeClienteSolicita'"
			:disabled="disabled"
			@input="nomeClienteSolicita = nomeClienteSolicita.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			v-maska
			data-maska="(##) # ####-####"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="telefoneClienteSolicita"
			v-if="type == 'telefoneClienteSolicita'"
			:disabled="disabled"
		/>
		<input
			type="number"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="editAvaliacoes_mensais"
			v-if="type == 'editCompanyAval'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="emailClienteSolicita"
			v-if="type == 'emailClienteSolicita'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyName"
			v-if="type == 'addCompanyName'"
			:disabled="disabled"
			@input="companyName = companyName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyTeamName"
			v-if="type == 'addCompanyTeamName'"
			:disabled="disabled"
			@input="companyTeamName = companyTeamName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyGroupName"
			v-if="type == 'addCompanyGroupName'"
			:disabled="disabled"
			@input="companyGroupName = companyGroupName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyAdminName"
			v-if="type == 'addCompanyAdminName'"
			:disabled="disabled"
			@input="companyAdminName = companyAdminName.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyAdminSurname"
			v-if="type == 'addCompanyAdminSurname'"
			:disabled="disabled"
			@input="companyAdminSurname = companyAdminSurname.replace(/\s{2,}/g, ' ').replace(/[0-9]/g, '')"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyAdminEmail"
			v-if="type == 'addCompanyAdminEmail'"
			:disabled="disabled"
			@input="
				companyAdminEmail = companyAdminEmail
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
					.replace(/\s/g, '')
			"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyAdminPassword"
			v-if="type == 'addCompanyAdminPassword'"
			:disabled="disabled"
			@input="
				companyAdminPassword = companyAdminPassword
					.replace(/\s/g, '')
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '')
			"
		/>
		<input
			type="text"
			:class="{
				errorInput: error && disabled == false,
				disabledInput: disabled == true,
				inputWithBorder: border == true
			}"
			:placeholder="placeholder"
			v-model="companyAvaliacoesMensais"
			v-maska
			data-maska="['#','##', '###', '#.###', '##.###', '###.###', '#.###.###']"
			data-maska-eager
			v-if="type == 'addCompanyAvaliacoesMensais'"
			:disabled="disabled"
		/>
		<input
			type="text"
			:class="{
				disabledInput: disabled == true,
				inputWithBorder: border == true,
				inputInfoPrecificacao: type == 'infoPrecificacao'
			}"
			:disabled="disabled"
			:placeholder="placeholder"
			v-if="type == 'infoPrecificacao'"
		/>
	</div>
</template>
