<script setup lang="ts">
import buttonVue from './MOBILE/buttons/button.vue';
import dropdownVue from './dropdown.vue';
import { ref, watch } from 'vue';
import inputGroup from './inputGroup.vue';
import { storeToRefs } from 'pinia';
import { useRequestEvaluation } from '../stores/requestEvaluation';
import VueDatePicker from '@vuepic/vue-datepicker';
import axios from 'axios';
import '../assets/vueDate.css';
const requestEvalution = useRequestEvaluation();
const { modelSolicitaOpen, comentarioSolicita, modalAvaliaSolicitacaoOpen, loadLists, placaSolicita, emailClienteSolicita, nomeClienteSolicita, telefoneClienteSolicita, documentExerciseSolicita, documentExerciseSolicitaError, documentExerciseSolicitaErrorText, emailClienteSolicitaError, emailClienteSolicitaErrorText, manualCliente, chaveReservaCliente, garantiaFabricaCliente, garantiaFabricaDataCliente, revisaoFabricaCliente, revisaoFabricaDataCliente, revisaoFabricaKm, tipoNegociacaoIndexCliente, tipoNegociacaoCliente, vendedorResponsavel, vendedorResponsavelDropdown } = storeToRefs(requestEvalution);

const cookies = document.cookie.split(";");
const tokenIndex = cookies.findIndex(
  (cookie) => cookie.startsWith("token_user=") || cookie.startsWith(" token_user=")
);
const tokenUser = cookies[tokenIndex].split("=")[1];

const closeModal = () => {
  modelSolicitaOpen.value = false;
  requestEvalution.$reset();
};
const selecionarEscolhaManual = (escolha: string) => {
  manualCliente.value = escolha;
};
const selecionarEscolhaChaveReserva = (escolha: string) => {
  chaveReservaCliente.value = escolha;
};
const selecionarEscolhaRevisao = (escolha: string) => {
  revisaoFabricaCliente.value = escolha;
  if (escolha == 'Não') {
    revisaoFabricaDataCliente.value = null;
    revisaoFabricaKm.value = '';
  } else {
    revisaoFabricaDataCliente.value = null;
    revisaoFabricaKm.value = '';
  }
};

const selecionarEscolhaGarantiaFabrica = (escolha: string) => {
  garantiaFabricaCliente.value = escolha;
  if (escolha == 'Não') {
    garantiaFabricaDataCliente.value = null;
  } else {
    garantiaFabricaDataCliente.value = null;
  }
};

async function listaVendedoresPossiveis() {
  axios.get(`${import.meta.env.VITE_AUTO_ME_USUARIO}/controle/usuarios/listaUsuariosPodemAvaliar`, {
    headers: {
      Authorization: 'Bearer ' + tokenUser,
      'Content-Type': 'application/json'
    }
  }).then((res) => {
    usuariosAvaliadores.value = res.data.dados;
  }).catch((err) => {
    console.log(err);
  })
}
listaVendedoresPossiveis();
interface tiposNegociacao {
  id_tipo_negociacao: number;
  tipo_negociacao: string;
}
const usuariosAvaliadores = ref([]);
const tiposNegociacaoArray = ref<tiposNegociacao[]>([]);
const placaError = ref(false);
const placaErrorText = ref('');
const buttonActive = ref(false);
const simOuNao = [{ escolha: 'Não' }, { escolha: 'Sim' }];
const maxDate = new Date();
const monthGarantiaCliente = ref();
const monthRevisao = ref();
maxDate.setFullYear(maxDate.getFullYear() + 3);
const chooseRadio = (index: number) => {
  tipoNegociacaoCliente.value = tiposNegociacaoArray.value[index].id_tipo_negociacao;
  tipoNegociacaoIndexCliente.value = index;
};
watch([() => placaSolicita.value, () => documentExerciseSolicita.value, () => emailClienteSolicita.value, () => garantiaFabricaCliente.value, () => garantiaFabricaDataCliente.value, () => revisaoFabricaCliente.value, () => revisaoFabricaDataCliente.value, () => revisaoFabricaKm.value], ([newVal1, newVal2, newVal3, newVal4, newVal5, newVal6, newVal7, newVal8], [oldVal1, oldVal2, oldVal3, oldVal4, oldVal5, oldVal6, oldVal7, oldVal8]) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const placaValida = newVal1.length > 0 && newVal1.length == 8;
  const documentExerciseValido = parseInt(newVal2) < new Date().getFullYear() + 1 && parseInt(newVal2) > 1970;
  const emailClienteValido = emailRegex.test(newVal3);
  const garantiaFabricaValido = newVal4 == 'Sim' ? (newVal5 !== undefined && newVal5 !== null) : true;
  const revisaoFabricaValido = newVal6 == 'Sim' ? (newVal7 !== undefined && newVal7 !== undefined) : true;
  const kmRevisaoFabricaValido = newVal6 == 'Sim' ? (newVal8.length > 0) : true;
  if (placaValida && (documentExerciseValido || newVal2.length == 0) && (emailClienteValido || newVal3.length == 0) && garantiaFabricaValido && revisaoFabricaValido && kmRevisaoFabricaValido) {
    buttonActive.value = true;
  } else {
    buttonActive.value = false;
  }
}, { immediate: true });
watch(placaSolicita, (val) => {
  if (val.length > 0 && val.length == 8) {
    placaError.value = false;
    placaErrorText.value = '';
  } else {
    placaError.value = true;
    placaErrorText.value = 'Placa necessita ter 8 caracteres';
  }
});
watch(documentExerciseSolicita, (val) => {
  if (val.length > 0) {
    if (parseInt(val) < new Date().getFullYear() + 1 && parseInt(val) > 1970) {
      documentExerciseSolicitaError.value = false;
      documentExerciseSolicitaErrorText.value = '';
    } else {
      documentExerciseSolicitaError.value = true;
      documentExerciseSolicitaErrorText.value = 'Exercicio do documento inválido';
    }
  } else {
    documentExerciseSolicitaError.value = false;
    documentExerciseSolicitaErrorText.value = '';
  }
});
watch(emailClienteSolicita, (val) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (val.length > 0) {
    if (emailRegex.test(val)) {
      emailClienteSolicitaError.value = false;
      emailClienteSolicitaErrorText.value = '';
    } else {
      emailClienteSolicitaError.value = true;
      emailClienteSolicitaErrorText.value = 'Email inválido';
    }
  } else {
    emailClienteSolicitaError.value = false;
    emailClienteSolicitaErrorText.value = '';
  }
});
axios
  .get(`${import.meta.env.VITE_AUTO_ME_AVALIACOES}/avaliacoes/tiposNegociacoes`, {
    headers: {
      Authorization: 'Bearer ' + tokenUser,
      'Content-Type': 'application/json'
    }
  })
  .then((res) => {
    tiposNegociacaoArray.value = res.data.dados;
  })
  .catch((err) => {
    console.log(err);
  });
</script>


<template>
  <div class="modalSolicitaAvalDesk">
    <div class="tituloModal">
      <div class="label">
        <h1>Solicitar avaliação</h1>
      </div>
      <div class="icon" @click="closeModal()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.65685 6.65711L9.88934 9.8896L12.3137 12.314M12.3137 12.314L6.65685 17.9708M12.3137 12.314L17.9706 17.9708M12.3137 12.314L17.9706 6.65711"
            stroke="#63636E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="track400"></div>
    <div class="contentModal">
      <inputGroup :type="'nomeClienteSolicita'" :border="true" :disabled="false" :error="false"
        :placeholder="'Digite o nome do cliente'">
        <template #label>Nome do cliente (opcional)</template>
      </inputGroup>
      <inputGroup :type="'telefoneClienteSolicita'" :border="true" :disabled="false" :error="false"
        :placeholder="'Digite o telefone do cliente'">
        <template #label>Telefone do cliente (opcional)</template>
      </inputGroup>
      <div style="width: 100%;display: flex;flex-direction:column;gap: var(--gap-m)">
        <inputGroup :type="'emailClienteSolicita'" :border="emailClienteSolicitaError ? false : true" :disabled="false"
          :error="emailClienteSolicitaError" :placeholder="'Digite o e-mail do cliente'">
          <template #label>E-mail do cliente (opcional)</template>
        </inputGroup>
        <div class="error" v-if="emailClienteSolicitaError">
          <p v-if="emailClienteSolicitaError">
            {{ emailClienteSolicitaErrorText }}
          </p>
        </div>
      </div>
      <div style="width: 100%;display: flex;flex-direction:column;gap: var(--gap-m)">
        <dropdownVue style="max-height: 256px; overflow: auto;" :values="usuariosAvaliadores" :all-width="true"
          :type="'vendedorResponsavelDropdown'" :value-dropdown="vendedorResponsavel"
          :placeholder="'Escolha o vendedor'" :clicked="vendedorResponsavelDropdown" :search="false" :error="false"
          :border="vendedorResponsavelDropdown" :open="vendedorResponsavelDropdown">
          <template #label>Vendedor responsável (opcional)</template>
        </dropdownVue>
      </div>
      <div style="width: 100%;display: flex;flex-direction:column;gap: var(--gap-m)">
        <inputGroup :type="'placaSolicita'" :border="placaError ? false : true" :disabled="false" :error="placaError"
          :placeholder="'Digite a placa'">
          <template #label>Placa</template>
        </inputGroup>
        <div class="error" v-if="placaError">
          <p v-if="placaError">
            {{ placaErrorText }}
          </p>
        </div>
      </div>
      <div style="width: 100%;display: flex;flex-direction:column;gap: var(--gap-m)">
        <inputGroup :type="'exercicioDocumentoSolicita'" :placeholder="'Digite o ano do exercício'"
          :error="documentExerciseSolicitaError" :disabled="false"
          :border="documentExerciseSolicitaError ? false : true">
          <template #label>Qual o ano de exercício do documento? (opcional)</template>
        </inputGroup>
        <div class="error" v-if="documentExerciseSolicitaError">
          <p v-if="documentExerciseSolicitaError">
            {{ documentExerciseSolicitaErrorText }}
          </p>
        </div>
      </div>
      <div class="radiosWrapper" style="padding: 0px 0px">
        <div class="label">
          <h1>Possui manual de manutenção? (opcional)</h1>
        </div>
        <div class="radio-group" v-for="(item, index) in simOuNao" @click="selecionarEscolhaManual(item.escolha)">
          <input type="radio" disabled="true" :checked="manualCliente === item.escolha" :id="item.escolha + 'Manual'"
            :name="item.escolha + 'Manual'" class="radio-input" />
          <label :for="item.escolha + 'Manual'" class="radio-label">
            <span class="radio-inner-circle"></span>
            {{ item.escolha }}
          </label>
        </div>

      </div>
      <div class="radiosWrapper" style="padding: 0px 0px">
        <div class="label">
          <h1>Possui chave reserva? (opcional)</h1>
        </div>
        <div class="radio-group" v-for="(item, index) in simOuNao" @click="selecionarEscolhaChaveReserva(item.escolha)">
          <input type="radio" disabled="true" :checked="chaveReservaCliente === item.escolha"
            :id="item.escolha + 'ChaveReserva'" :name="item.escolha + 'ChaveReserva'" class="radio-input" />
          <label :for="item.escolha + 'ChaveReserva'" class="radio-label">
            <span class="radio-inner-circle"></span>
            {{ item.escolha }}
          </label>
        </div>
      </div>
      <div class="radiosWrapper" style="padding: 0px 0px">
        <div class="label">
          <h1>Está em garantia de fábrica? (opcional)</h1>
        </div>
        <div class="radio-group" v-for="(item, index) in simOuNao"
          @click="selecionarEscolhaGarantiaFabrica(item.escolha)">
          <input type="radio" disabled="true" :checked="garantiaFabricaCliente === item.escolha"
            :id="item.escolha + 'GarantiaFabrica'" :name="item.escolha + 'GarantiaFabrica'" class="radio-input" />
          <label :for="item.escolha + 'GarantiaFabrica'" class="radio-label">
            <span class="radio-inner-circle"></span>
            {{ item.escolha }}
          </label>
        </div>
      </div>
      <div class="dateWrapper" v-if="garantiaFabricaCliente === 'Sim'" style="padding: 0px 0px">
        <div class="label">
          <h1>Até quando vai a garantia de fábrica?</h1>
        </div>
        <VueDatePicker :style="{ border: garantiaFabricaDataCliente !== null ? '1px solid var(--cr-p-azulEscuro)' : ''}"
          style="border-radius: var(--border-radius-s)" v-model="garantiaFabricaDataCliente"
          :placeholder="'Escolha a data do fim da garantia'" month-picker auto-apply :min-date="new Date()"
          :max-date="maxDate" locale="pt-Br">
          <template #clear-icon="{ clear }">
            <img class="input-slot-image" src="../assets/icons/x.svg" @click="clear" />
          </template>
        </VueDatePicker>
      </div>
      <div class="radiosWrapper" style="padding: 0px 0px">
        <div class="label">
          <h1>Todas as revisões estão feitas? (opcional)</h1>
          <p>Considere apenas concessionárias autorizadas</p>
        </div>
        <div class="radio-group" v-for="(item, index) in simOuNao" @click="selecionarEscolhaRevisao(item.escolha)">
          <input type="radio" disabled="true" :checked="revisaoFabricaCliente === item.escolha"
            :id="item.escolha + 'Revisoes'" :name="item.escolha + 'Revisoes'" class="radio-input" />
          <label :for="item.escolha + 'Revisoes'" class="radio-label">
            <span class="radio-inner-circle"></span>
            {{ item.escolha }}
          </label>
        </div>
      </div>
      <div class="dateWrapper" v-if="revisaoFabricaCliente === 'Sim'" style="padding: 0px 0px">
        <div class="label">
          <h1>Quando foi feita a última revisão?</h1>
        </div>
        <VueDatePicker v-model="revisaoFabricaDataCliente" :placeholder="'Escolha a data da última revisão'"
          :style="{ border: revisaoFabricaDataCliente !== null ? '1px solid var(--cr-p-azulEscuro)' : '' }"
          style="border-radius: var(--border-radius-s)" month-picker auto-apply :max-date="new Date()" locale="pt-Br">
          <template #clear-icon="{ clear }">
            <img class="input-slot-image" src="../assets/icons/x.svg" @click="clear" />
          </template>
        </VueDatePicker>
      </div>
      <div class="kmRevisao">
        <inputGroup style="padding: 0px 0px" v-if="revisaoFabricaCliente === 'Sim'" :type="'kmRevisaoCliente'"
          :placeholder="'Digite a km da última revisão'" :error="false" :disabled="false" :border="true">
          <template #label>Qual a km da última revisão?</template>
        </inputGroup>
      </div>
      <div class="radiosWrapper" style="padding: 0px 0px">
        <div class="label">
          <h1>Tipo de negociação (opcional)</h1>
        </div>
        <div class="radio-group" v-for="(item, index) in tiposNegociacaoArray" style="height: auto">
          <input type="radio" :checked="tipoNegociacaoIndexCliente === index" :id="item.tipo_negociacao + index"
            :name="item.tipo_negociacao + index" class="radio-input" />
          <label :for="item.tipo_negociacao + index" class="radio-label" style="padding: 10px"
            @click="chooseRadio(index)">
            <span class="radio-inner-circle"></span>
            <div class="radio-content">
              <h1>{{ item.tipo_negociacao }}</h1>
            </div>
          </label>
        </div>
      </div>
      <div class="inputGroup">
        <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
          <h1 style="font: var(--b-s-Ag-4);color: var(--cr-p-azulEscuro)">Comentário (opcional)</h1>
          <p>Ajude o avaliador a localizar o veículo.</p>
        </div>
        <textarea name="mensagem" rows="6" maxlength="254" v-model="comentarioSolicita"></textarea>
      </div>
    </div>
    <div class="buttonWrapper">
      <buttonVue :type="buttonActive == false ? 'custom' : 'active'" :action="'requestEvaluation'"
        :color="buttonActive == false ? 'deactive' : 'active'">
        <template #labelButton> Solicitar avaliação</template>
      </buttonVue>
    </div>
  </div>
</template>