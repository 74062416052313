<script setup lang="ts">
import buttonVue from './MOBILE/buttons/button.vue';
import { storeToRefs } from 'pinia';
import { useLogin } from '@/stores/login';
import axios from 'axios';
import router from '@/router';
const login = useLogin();
const { modalNotificationOpen, idUsuario } = storeToRefs(login);
const closeModal = () => {
  modalNotificationOpen.value = false;
};

function urlB64ToUint8Array(base64String: any) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const saveSubscription = async (subscription: any) => {
  const cookies = document.cookie.split(";");
  const tokenIndex = cookies.findIndex(
    (cookie) => cookie.startsWith("token_user=") || cookie.startsWith(" token_user=")
  );
  const tokenUser = cookies[tokenIndex].split("=")[1];
  if (subscription.keys.p256dh) {
    const response = await axios.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/notificacoes/cadastraServiceWorker`, {
      id_usuario: idUsuario.value,
      service_worker: subscription
    }, {
      headers: {
        Authorization: 'Bearer ' + tokenUser,
        'Content-Type': 'application/json'
      }
    });
    return response;
  }
};

async function requestPermission() {
  if (!("Notification" in window)) {
    alert('Seu navegador não oferece suporte a notificações');
    closeModal();
  } else {
    if (Notification.permission === "granted") {
      closeModal();
      registerServiceWorker;
    } else if (Notification.permission !== "denied") {
      await Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          closeModal();
          registerServiceWorker();
        } else if (permission === 'denied') {
          closeModal();
        }
      });
    }
  }
};

async function registerServiceWorker() {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    let firebaseMessagingSWRegistered = false;
    let firebaseMessagingSWRegistration = null;

    for (const registration of registrations) {
      if (registration.active?.scriptURL === `${import.meta.env.VITE_SERVER}/firebase-messaging-sw.js` || registration.active?.scriptURL === 'http://127.0.0.1:5173/firebase-messaging-sw.js') {
        firebaseMessagingSWRegistered = true;
        firebaseMessagingSWRegistration = registration;
        break;
      }
    }

    if (firebaseMessagingSWRegistered) {
      console.log('Service worker firebase-messaging-sw.js já está registrado. Subscription:', await firebaseMessagingSWRegistration?.pushManager.getSubscription());
    } else {
      await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/firebase-messaging-sw', type: 'classic' })
        .then(async (registration) => {
          setTimeout(async () => {
            const subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array('BMmttnNBGSi2Lpe93oRNBL_Bz8yZFXxfuGiRW3grXh6ofCe8gyOFPbzrcERtiKqkvZaY2X37ZLi7Coh0QkYg6Kw')
            }).then(async (res) => {
              await saveSubscription(res);
            }).catch((err) => {
              console.log(err);
            });
          }, 600);
        })
        .catch(async(error) => {
          console.log('Falha ao registrar o service worker firebase-messaging-sw.js:', error);
        });
    }
  } else {
    alert('Service Worker não é suportado neste navegador.');
    closeModal();
  }
}
</script>


<template>
  <div class="modalAvaliaSolicitacaoAvalDesk">
    <div class="tituloModal">
      <div class="label">
        <h1>Notificações</h1>
      </div>
      <div class="icon" @click="closeModal()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.65685 6.65711L9.88934 9.8896L12.3137 12.314M12.3137 12.314L6.65685 17.9708M12.3137 12.314L17.9706 17.9708M12.3137 12.314L17.9706 6.65711"
            stroke="#63636E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="track400"></div>
    <div class="contentModal">
      <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro);">Gostaríamos de enviar
        notificações para facilitar seu fluxo de trabalho.
        Você aceita receber notificações?
      </h1>
    </div>
    <div class="buttonWrapper" style="padding: var(--padding-xl);position: relative;">
      <div class="button" style="background: var(--cr-p-azulMedio);" @click="requestPermission()">
        <p>Aceitar</p>
      </div>
    </div>
  </div>
</template>