<script setup lang="ts">
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';
import { OnClickOutside } from '@vueuse/components';
import axios from 'axios';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useEvaluationView } from '../stores/evaluationView';
import { useLogin } from '../stores/login';
const viewEvaluation = useEvaluationView();
const userLogin = useLogin();
const { modalEditaValor, editValorFinal, errorValorFinal, errorTextValorFinal } = storeToRefs(viewEvaluation);
const { id_empresa, notificacoesAtivas, modalDesvioPagamento, errorDesvioPagamento, errorTextDesvioPagamento } = storeToRefs(userLogin);

const clickOutside = () => {
  modalDesvioPagamento.value = false;
};
const closeModal = () => {
  modalDesvioPagamento.value = false;
};
const buttonActive = ref(false);

watch(notificacoesAtivas.value, (val) => {
  const item = val.find(item => item.id_notificacao_possivel === 4);
  const formatPercentage = (value: string) => {
    const floatValue = parseFloat(value);
    return Number.isInteger(floatValue) ? floatValue.toFixed(0) : floatValue.toFixed(2);
  };
  if (item) {
    if (item.variavel.length > 0) {
      const itemNumber = item.variavel.replace('%', '').replace(',', '.');
      buttonActive.value = parseFloat(formatPercentage(itemNumber)) > 0;
    } else {
      buttonActive.value = false;
    }
  } else {
    buttonActive.value = false;
  }

}, { immediate: true });

</script>


<template>
  <OnClickOutside @trigger="clickOutside()" class="clickOutside">
    <div class="modalEditValorModal">
      <div class="body">
        <div class="titulo">
          <div class="label">
            <h1>Editar variável</h1>
          </div>
          <div class="icon" @click="closeModal">
            <img src="/src/assets/icons/x.svg" />
          </div>
        </div>
        <div class="inputWrapper">
          <inputGroup :disabled="false" :placeholder="'%'" :type="'editDesvioPagamento'" :error="errorDesvioPagamento">
            <template #label>Percentual de desvio</template>
            <template #auxiliarText>Receber notificações com desvios maior que o apontado</template>
          </inputGroup>
          <div class="error" v-if="errorDesvioPagamento">
            <p>
              {{ errorTextDesvioPagamento }}
            </p>
          </div>
        </div>
        <div class="content">
          <buttonVue :type="buttonActive == true ? 'active' : 'custom'" :action="'saveChangesDesvioPagamento'"
            :color="'deactive'">
            <template #labelButton>Salvar alterações</template>
          </buttonVue>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>