import './assets/main.css';
import { createApp } from 'vue';
import Toast, { POSITION, type PluginOptions } from 'vue-toastification';
import './assets/vueToastification.css';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import '../registerSW.js';
import VueTippy from 'vue-tippy';
import Hotjar from 'vue-hotjar';
import * as Sentry from '@sentry/vue';

const options: PluginOptions = {
	transition: 'Vue-Toastification__slideBlurred',
	position: POSITION.BOTTOM_CENTER,
	maxToasts: 20,
	newestOnTop: true
};

const app = createApp(App);
app.use(createPinia());
app.use(VueTippy, { directive: 'tippy', component: 'tippy' });
app.use(Toast, options);

if (import.meta.env.MODE !== 'debug') {
	app.use(Hotjar, {
		id: import.meta.env.VITE_HOTJAR_ID
	});
	Sentry.init({
		app,
		environment: import.meta.env.MODE,
		dsn: import.meta.env.VITE_SENTRY_DNS,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration(), Sentry.browserProfilingIntegration()],
		// Tracing
		tracesSampleRate: 1.0, //  Capture 100% of the transactions
		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: ['localhost', /^https:\/\/autome.com.br/],
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	});
}

app.use(router);
app.mount('#app');
