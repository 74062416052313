<script setup lang="ts">
import { storeToRefs, mapActions } from 'pinia';
import { useHeaderInfos } from '../../../stores/headerInfos';
import { useLogin } from '../../../stores/login';
import menuDesk from '../menu/menuDesk.vue';
import router from '@/router';
const infoLogin = useLogin();
const infoHeader = useHeaderInfos();
const { abertoMenuStart } = storeToRefs(infoHeader);
const { nome, sobrenome, cargo, empresa } = storeToRefs(infoLogin);
const openMenuDesk = () => {
	infoHeader.abrirMenuStart();
};
const voltarInicio = () => {
	router.push({ name: 'inicio'})
}
</script>

<template>
	<header>
		<div class="logo" @click="voltarInicio" style="cursor: pointer;">
			<img src="../../../assets/AutomeLogoCompleta.svg" alt="Logo Autome" id="logo" />
		</div>
		<div class="menuDesktop">
			<div class="infos">
				<div class="nome">
					<h1>Olá, {{ nome }} {{ sobrenome }}!</h1>
					<p>{{ cargo }} | {{ empresa }}</p>
				</div>
			</div>
			<div class="iconMenu" @click="openMenuDesk()">
				<svg class="menuDesk" width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="12" cy="4" r="2" fill="#121F40" />
					<circle cx="20" cy="4" r="2" fill="#121F40" />
					<circle cx="4" cy="4" r="2" fill="#121F40" />
				</svg>
				<p>Menu</p>
			</div>
		</div>
	</header>
	<menuDesk />
</template>
