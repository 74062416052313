if ('serviceWorker' in navigator) {
	const isProd = process.env.NODE_ENV === 'production';
	const workerURL = isProd ? '/sw.js' : '/dev-sw.js?dev-sw';

	navigator.serviceWorker
		.register(workerURL, { scope: '/' })
		.then((registration) => {
			console.log(`Service Worker registrado com sucesso: ${workerURL}`);
		})
		.catch((error) => {
			console.error('Erro ao registrar o Service Worker:', error);
		});
}