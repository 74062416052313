import { defineStore } from 'pinia';

export const useChangePassword = defineStore('changePassword', {
	state: () => ({
		senhaAntiga: '',
		novaSenha: '',
		confirmSenha: '',
		showPassword: false,
		clickButtonChangePassword: false,
		errorChangePassword: false,
		errorTextChangePassword: '',
		changePasswordSucess: false,
		haveAErrorChangePassword: false
	})
});
