import { defineStore } from 'pinia';

export const useRequestEvaluation = defineStore('requestEvaluation', {
  state: () => ({
    idSolicitacao: '' as any,
    comentarioSolicita: '',
    placaSolicita: '',
    nomeClienteSolicita: '',
    telefoneClienteSolicita: '',
    emailClienteSolicita: '',
    vendedorResponsavel: '',
    vendedorResponsavelId: 0,
    vendedorResponsavelDropdown: false,
    emailClienteSolicitaError: false,
    emailClienteSolicitaErrorText: '',
    documentExerciseSolicita: '',
    documentExerciseSolicitaError: false,
    documentExerciseSolicitaErrorText: '',
    manualCliente: '' as any,
    chaveReservaCliente: '' as any,
    garantiaFabricaCliente: '' as any,
    garantiaFabricaDataCliente: '' as any,
    tipoNegociacaoCliente: '' as any,
    tipoNegociacaoIndexCliente: null as any,
    revisaoFabricaCliente: '' as any,
    revisaoFabricaKm: '', 
    revisaoFabricaDataCliente: '' as any,
    modelSolicitaOpen: false,
    modalAvaliaSolicitacaoOpen: false,
    loadLists: false,
    isEvaluation: false,
    clickedSolicitaAvaliacao: false
  })
});
