import { defineStore } from 'pinia';

export const useAutoPay = defineStore('autoPay', {
  state: () => ({
    tipoVeiculoAutoPay: 0,
    classificacaoMetalAutoPay: 0,
    regras: {
      empresa: '',
      equipes: [{
        equipe: '',
        id_equipe: 0,
        grupos: [{
          grupo: '',
          id_grupo: 0,
          regras: [{
            ativo: 0,
            data_modificacao: '',
            id_metal: 0,
            id_usuario_modificacao: 0,
            id_veiculo_tipo: 0,
            nome_usuario_modificacao: ''
          }]
        }],
        regras: [{
          ativo: 0,
          data_modificacao: '',
          id_metal: 0,
          id_usuario_modificacao: 0,
          id_veiculo_tipo: 0,
          nome_usuario_modificacao: ''
        }]
      }],
      id_empresa: 0,
      regras: [{
        ativo: 0,
        data_modificacao: '',
        id_metal: 0,
        id_usuario_modificacao: 0,
        id_veiculo_tipo: 0,
        nome_usuario_modificacao: ''
      }]
    },
    reload: false,
    regrasDefault: [] as any,
    clickedAutoPay: false,
    alteracoes: [] as any
  })
});
