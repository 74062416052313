<script setup lang="ts">
import { onMounted } from 'vue';
import { useSplash } from '../stores/splash';
import { storeToRefs } from 'pinia';
const splashStore = useSplash();
const { show, rodando, loadingContent, rodou } = storeToRefs(splashStore);

onMounted(() => {
	setTimeout(() => {
		rodando.value = true;
	}, 2500);
	setTimeout(() => {
		show.value = false;
		rodando.value = false;
		rodou.value = true;
	}, 3700);
});
</script>

<template>
	<transition name="fade">
		<div v-if="show && rodou !== true" class="splash">
			<div class="logo">
				<img src="../assets/AutomeLogoSolo.svg" alt="logo Autome" id="logo1" :class="{ sairLogo: rodando }" />
				<img src="../assets/Escrita.svg" alt="logo Autome" class="Escrita" :class="{ sairEscrita: rodando, EscritaShow: rodando }" />
			</div>
		</div>
	</transition>
</template>
