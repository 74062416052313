import { defineStore } from 'pinia';

export const useHeaderInfos = defineStore('headerInfos', {
	state: () => ({
		abertoMenuStart: false,
		abertoMenuRoute: false
	}),
	actions: {
		abrirMenuStart() {
			this.abertoMenuStart = true;
		},
		fecharMenuStart() {
			this.abertoMenuStart = false;
		},
		onClickOutsideMenu() {
			if (this.abertoMenuStart == true) {
				this.abertoMenuStart = false;
			}
		}
	}
});
