<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useServices } from '../../../stores/services';
import { useInfosUser } from '../../../stores/userInfos';
import { useLogin } from '../../../stores/login';
import loader from '../../loader.vue';
import { storeToRefs } from 'pinia';
import { ref, watchEffect, watch } from 'vue';
import { useGroupTeamControl } from '../../../stores/groupTeamControl';
import { useRequestEvaluation } from '../../../stores/requestEvaluation';
import casaSvg from '../../../assets/icons/casa.svg';
import relatoriosSvg from '../../../assets/icons/relatorios.svg';
import relatoriosSvgInativo from '../../../assets/icons/relatoriosInativo.svg';
import usuariosGrupoSvg from '../../../assets/icons/usuariosGrupo.svg';
import usuariosGrupoSvgInativo from '../../../assets/icons/usuariosGrupoInativo.svg';
import empresaSvg from '../../../assets/icons/empresa.svg';
import empresaSvgInativo from '../../../assets/icons/empresaInativo.svg';
import solicitarSvg from '../../../assets/icons/solicitar.svg';
import solicitarSvgInativo from '../../../assets/icons/solicitarInativo.svg';
import solicitarPendenteSvg from '../../../assets/icons/solicitarPendente.svg';
import solicitarPendenteInativoSvg from '../../../assets/icons/solicitarPendenteInativo.svg';
import maisFilledSvg from '../../../assets/icons/maisFilled.svg';
import maisFilledInativoSvg from '../../../assets/icons/maisFilledInativo.svg';
import cifraoPreenchidoSvg from '../../../assets/icons/cifraoPreenchido.svg';
import cifraoPreenchidoInativoSvg from '../../../assets/icons/cifraoPreenchidoInativo.svg';
import pranchetaSvg from '../../../assets/icons/prancheta.svg';
import pranchetaInativoSvg from '../../../assets/icons/pranchetaInativo.svg';
import listaUncheckedSvg from '../../../assets/icons/listaUnchecked.svg';
import listaUncheckedInativoSvg from '../../../assets/icons/listaUncheckedInativo.svg';
import balaoNotificacaoSvg from '../../../assets/icons/balaoNotificacao.svg';
import balaoNotificacaoInativoSvg from '../../../assets/icons/balaoNotificacaoInativo.svg';
import autoPaySvg from '../../../assets/icons/autoPay.svg';
import autoPayInativoSvg from '../../../assets/icons/autoPayInativo.svg';
import integracaoSvg from '../../../assets/icons/integracao.svg';
import integracaoInativoSvg from '../../../assets/icons/integracaoInativo.svg';
const groupTeamControl = useGroupTeamControl();
const { modalShowTabGroupTeam } = storeToRefs(groupTeamControl);
const infosUser = useInfosUser();
const servicesInfo = useServices();
const requestEvalution = useRequestEvaluation();
const login = useLogin();
const { users, groupsTeams, avaliacao, integracao, veiculo, regraDestino, regraPrecificacao, avaliacoes, listaSolicitacoesPendentes, solicitarAvaliacoes, autoPay, personalizarNotificacoes, relatorios, empresas } = storeToRefs(servicesInfo);
const { mobile, desktop } = storeToRefs(infosUser);
const { id_cargo, acessos, modalDesvioPagamento, modalNotificacoes, infosSearch } = storeToRefs(login);
const { modelSolicitaOpen } = storeToRefs(requestEvalution);

const router = useRouter();
const mouseEnterGroupTeam = () => {
	modalShowTabGroupTeam.value = true;
};
const routePushTo = (route: string, status: string) => {
	if (status == 'on') {
		if (route == 'gruposEquipes') {
			mouseEnterGroupTeam();
		} else if (route == 'solicitarAvaliacao') {
			modelSolicitaOpen.value = true;
		} else if (route == 'usuarios') {
			router.push({ name: 'controleUsuario' });
		} else if (route == 'personalizarNotificacoes') {
			modalNotificacoes.value = true;
		}
		else {
			router.push({ name: route });
		}
	}
};
function formatLabel(hotkeyItem: any) {
	if (hotkeyItem.action === 'gruposEquipes') {
		return `${hotkeyItem.label}`;
	} else {
		return hotkeyItem.label;
	}
}
interface HotkeyItem {
	idCargo?: Array<number>;
	icon: string;
	postItColor: string;
	action: string;
	status: string;
	label: string;
	acess?: Array<number>;
}
interface AcessoAtivo {
	ativo: number;
	id_acesso: number;
}
const allHotkeys = ref<HotkeyItem[]>([]);
const hotkeys = ref<HotkeyItem[]>([]);
const acessosAtivos = ref<AcessoAtivo[]>([]);
const showAccordion = ref(false);
function verifyServiceInfo() {
	const interval = setInterval(() => {
		if (infosSearch && infosSearch.value) {
			hotkeys.value = [];
			acessos.value.forEach((acesso: any) => {
				if (acesso.ativo == 1) {
					acessosAtivos.value.push(acesso);
				}
			});
			allHotkeys.value = [
				{
					icon: casaSvg,
					postItColor: 'none',
					action: 'inicio',
					status: 'on',
					label: 'Inicio'
				},
				{
					icon: relatorios.value === 'on' ? relatoriosSvg : relatoriosSvgInativo,
					postItColor: 'none',
					action: 'relatorios',
					status: relatorios.value,
					label: 'Relatórios',
					acess: [100, 101, 102]
				},
				{
					idCargo: [0, 1],
					icon: users.value === 'on' ? usuariosGrupoSvg : usuariosGrupoSvgInativo,
					postItColor: 'none',
					action: 'controleUsuario',
					status: users.value,
					label: 'Usuários'
				},
				{
					idCargo: [0],
					icon: empresas.value === 'on' ? empresaSvg : empresaSvgInativo,
					postItColor: 'none',
					action: 'controleEmpresa',
					status: empresas.value,
					label: 'Empresas'
				},
				{
					idCargo: [0, 1],
					icon: groupsTeams.value === 'on' ? empresaSvg : empresaSvgInativo,
					postItColor: 'none',
					action: 'gruposEquipes',
					status: groupsTeams.value,
					label: 'Grupos e equipes'
				},
				{
					icon: solicitarAvaliacoes.value === 'on' ? solicitarSvg : solicitarSvgInativo,
					postItColor: 'none',
					action: 'solicitarAvaliacao',
					status: solicitarAvaliacoes.value,
					label: 'Solicitar avaliação',
					acess: [1]
				},
				{
					icon: listaSolicitacoesPendentes.value === 'on' ? solicitarPendenteSvg : solicitarPendenteInativoSvg,
					postItColor: 'none',
					action: 'listaSolicitacoes',
					status: listaSolicitacoesPendentes.value,
					label: 'Solicitações pendentes',
					acess: [300, 301, 302]
				},
				{
					icon: avaliacao.value === 'on' && veiculo.value === 'on' ? maisFilledSvg : maisFilledInativoSvg,
					postItColor: 'none',
					action: 'novaAvaliacao',
					status: avaliacao.value,
					label: 'Nova avaliação',
					acess: [2]
				},
				{
					icon: regraPrecificacao.value === 'on' ? cifraoPreenchidoSvg : cifraoPreenchidoInativoSvg,
					postItColor: 'none',
					action: 'regrasPrecificacao',
					status: regraPrecificacao.value,
					label: 'Regras precificação',
					acess: [10]
				},
				{
					icon: avaliacoes.value === 'on' ? pranchetaSvg : pranchetaInativoSvg,
					postItColor: 'none',
					action: 'avaliacoes',
					status: avaliacoes.value,
					label: 'Avaliações'
				},
				{
					icon: regraDestino.value === 'on' ? listaUncheckedSvg : listaUncheckedInativoSvg,
					postItColor: 'none',
					action: 'regrasDestino',
					status: regraDestino.value,
					label: 'Regras destino',
					acess: [11]
				},
				{
					icon: personalizarNotificacoes.value === 'on' ? balaoNotificacaoSvg : balaoNotificacaoInativoSvg,
					postItColor: 'none',
					action: 'personalizarNotificacoes',
					status: personalizarNotificacoes.value,
					label: 'Personalizar notificações'
				},
				{
					icon: autoPay.value === 'on' ? autoPaySvg : autoPayInativoSvg,
					postItColor: 'none',
					action: 'autoPay',
					status: autoPay.value,
					label: 'Habilitar AutoPay',
					acess: [201, 202, 203]
				},
				{
					idCargo: [0, 1],
					icon: integracao.value === 'on' ? integracaoSvg : integracaoInativoSvg,
					postItColor: 'none',
					action: 'integracao',
					status: integracao.value,
					label: 'Integrações'
				}
			];
			watchEffect(() => {
				hotkeys.value = allHotkeys.value.filter((hotkey) => {
					if (hotkey.idCargo !== undefined) {
						return hotkey.idCargo.some((cargo) => {
							return cargo === id_cargo.value;
						});
					} else if (hotkey.acess !== undefined) {
						return hotkey.acess.some((acesso) => {
							return acessosAtivos.value.some((acessoAtivo) => {
								return acessoAtivo.id_acesso === acesso;
							});
						});
					} else {
						return true;
					}
				});
			});
			clearInterval(interval);
		}
	}, 2000);
}
verifyServiceInfo();
const isActiveHotkey = (action: string) => {
	const currentRoute = router.currentRoute.value;
	return currentRoute.path === `/${action}`;
};

</script>

<template>
	<div class="nav">

		<a v-if="hotkeys.length > 0" v-for="(hotkeyItem, index) in hotkeys"
			@click="routePushTo(hotkeyItem.action, hotkeyItem.status)"
			:class="{ 'router-link-active': isActiveHotkey(hotkeyItem.action), 'router-link-exact-active': isActiveHotkey(hotkeyItem.action) }">
			<div class="hotkey">
				<img :src="hotkeyItem.icon" alt="icon" />
				<p :style="{ color: hotkeyItem.status == 'off' ? '#BEBEC2' : '#121F40' }"
					v-if="hotkeyItem.action !== 'gruposEquipes' && hotkeyItem.action !== 'autoPay'">{{ hotkeyItem.label }}</p>
				<p v-else-if="hotkeyItem.action == 'gruposEquipes'">Grupos <br> e equipes</p>
				<p v-else-if="hotkeyItem.action == 'autoPay'">Habilitar <br> AutoPay</p>
			</div>
		</a>
		<div v-else style="width: 100%;display: flex;justify-content: center;">
			<loader  />
		</div>
	</div>
</template>
