<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useControlUser } from '../stores/userControl';
import { useGroupTeamControl } from '../stores/groupTeamControl';
import { vehicle } from '../stores/vehicle';
import { usePricing } from '../stores/pricing';
import { useFilters } from '../stores/filters';
import { useRelatorios } from '../stores/relatorios';
import { useRequestEvaluation } from '../stores/requestEvaluation';
import { useCompanyControl } from '../stores/companyControl';
import { ref, watch } from 'vue';
const controlUser = useControlUser();
const companyControl = useCompanyControl();
const controlGroupTeam = useGroupTeamControl();
const requestEvaluation = useRequestEvaluation();
const vehicleInfo = vehicle();
const pricing = usePricing();
const filters = useFilters();
const relatorios = useRelatorios();
const { dropdownJob, dropdownGroup, editUserJob, editUserJobId, editUserTeam, editUserTeamId, editUserGroup, editUserGroupId, newUserJob, newUserJobId, newUserGroup, newUserGroupId, newUserTeam, newUserTeamId, transfUserGroup, transfUserGroupId, transfUserTeam, transfUserTeamId, transfUserName, transfUserSurname, transfUserId, dropdownTransf } = storeToRefs(controlUser);
const { dropdownGroupE, dropdownTeam, editTeam, teamId, newTeamGroup, newTeamGroupId } = storeToRefs(controlGroupTeam);
const { dropdownMarca, dropdownModelo, marca, marcaId, anoModelo, dropdownAno, id_combustivel, combustivel, combustivelId, dropdownCombustivel, modelo, modeloId, versao, idVersao, dropdownVersao, cor, idCor, dropdownCores, carroceria, carroceriaId, dropdownCarroceria, transmissao, transmissaoId, dropdownTransmissao, portas, portasId, portasDropdown, id_fipe, id_fipe_historico, codigo_fipe, anoFabricacao, clickMarca, clickModelo, clickVersao, eletricoAval } = storeToRefs(vehicleInfo);
const { marcaFilter, modeloFilter, placaFilter, equipeFilter, grupoFilter, usuarioFilter, blindagemFilter, tetoSolarFilter, statusFilter, marcaDropdownOpen, modeloDropdownOpen, grupoDropdownOpen, placaDropdownOpen, equipeDropdownOpen, usuarioDropdownOpen, marcaFilterClicked, modeloFilterClicked, equipeFilterClicked, grupoFilterClicked, placaFilterClicked, usuarioFilterClicked, statusFilterClicked, statusDropdownOpen, anoModeloDropdownOpen, anoModeloFilter, anoModeloFilterClicked, renegociadoDropdownOpen, renegociadoFilter, renegociadoFilterCliked } = storeToRefs(filters);
const { dropdownUfs, uf, ufId } = storeToRefs(pricing);
const { vendedorResponsavelDropdown, vendedorResponsavel, vendedorResponsavelId } = storeToRefs(requestEvaluation);
const { companyUf, companyUfId, companyDropdownUfs } = storeToRefs(companyControl);
const { agruparPor, agrupaPorDropdownOpen, equipeRelatorios, equipeRelatoriosDropdownOpen, grupoRelatorios, grupoRelatoriosDropdownOpen, tipoAvaliacaoRelatorios, tipoAvaliacaoRelatoriosDropdownOpen, classificacaoMetalRelatorios, classificacaoMetalRelatoriosDropdownOpen, dataFinalRelatorios, dataInicialRelatorios, dateAllRelatorios, statusRelatorios, statusRelatoriosDropdownOpen, tipoNegociacaoRelatorios, tipoNegociacaoRelatoriosDropdownOpen, tipoVeiculoRelatorios, tipoVeiculoRelatoriosDropdownOpen, usuarioRelatorios, usuarioRelatoriosDropdownOpen } = storeToRefs(relatorios);
const props = defineProps<{
	values: Array<any>;
	type: string;
	border?: boolean;
	error?: boolean;
	allWidth?: boolean;
	placeholder: string;
	valueDropdown: any;
	deactive?: boolean;
	search?: boolean;
	open: boolean;
}>();
const filteredValuesMarcas = ref<any[]>([]);
const filteredValuesMarcasFilter = ref<any[]>([]);
const filteredValuesModelos = ref<any[]>([]);
const filteredValuesModelosFilter = ref<any[]>([]);
const filteredValuesPlacasFilter = ref<any[]>([]);
const filteredValuesVersoes = ref<any[]>([]);
const filteredValuesUfs = ref<any[]>([]);
const filteredValuesUfsCompany = ref<any[]>([]);
const filteredValuesEquipesFilter = ref<any[]>([]);
const filteredValuesGruposFilter = ref<any[]>([]);
const filteredValuesUsuariosFilter = ref<any[]>([]);
const filteredValuesAnosModelosFilter = ref<any[]>([]);

function searchOnValuesUfs() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item.uf) {
			return item.uf.toUpperCase().startsWith(uf.value.toUpperCase());
		}
		return false;
	});
	filteredValuesUfs.value = newFilteredValues;
}
function searchOnValuesUfsCompany() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item.uf) {
			return item.uf.toUpperCase().startsWith(companyUf.value.toUpperCase());
		}
		return false;
	});
	filteredValuesUfsCompany.value = newFilteredValues;
}
function searchOnValuesMarcasFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(marcaFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesMarcasFilter.value = newFilteredValues;
}
function searchOnValuesModelosFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(modeloFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesModelosFilter.value = newFilteredValues;
}
function searchOnValuesPlacasFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(placaFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesPlacasFilter.value = newFilteredValues;
}
function searchOnValuesEquipesFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(equipeFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesEquipesFilter.value = newFilteredValues;
}
function searchOnValuesGruposFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(grupoFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesGruposFilter.value = newFilteredValues;
}
function searchOnValuesUsuariosFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.toUpperCase().startsWith(usuarioFilter.value.toUpperCase());
		}
		return false;
	});
	filteredValuesUsuariosFilter.value = newFilteredValues;
}
function searchOnValuesAnosModelosFilter() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item) {
			return item.startsWith(anoModeloFilter.value);
		}
		return false;
	});
	filteredValuesAnosModelosFilter.value = newFilteredValues;
}
function searchOnValuesMarcas() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item.marca) {
			return item.marca.toUpperCase().startsWith(marca.value.toUpperCase());
		}
		return false;
	});
	filteredValuesMarcas.value = newFilteredValues;
}
function searchOnValuesModelos() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item.modelo) {
			return item.modelo.toUpperCase().startsWith(modelo.value.toUpperCase());
		}
		return false;
	});
	filteredValuesModelos.value = newFilteredValues;
}
function searchOnValuesVersoes() {
	const newFilteredValues = props.values.filter((item: any) => {
		if (item.versao) {
			return item.versao.toUpperCase().startsWith(versao.value.toUpperCase());
		}
		return false;
	});
	filteredValuesVersoes.value = newFilteredValues;
}
watch(marca, (val, oldVal) => {
	if (val !== oldVal) {
		searchOnValuesMarcas();
		filteredValuesModelos.value = [];
	}
});
watch(modelo, (val, oldVal) => {
	if (val !== oldVal) {
		searchOnValuesModelos();
		filteredValuesVersoes.value = [];
	}
});
watch(versao, (val, oldVal) => {
	if (val !== oldVal) {
		searchOnValuesVersoes();
	}
});
function openAndCloseModal(type: string) {
	if (props.deactive !== true) {
		if (type == 'job' || type == 'jobEdit') {
			dropdownJob.value = !dropdownJob.value;
		}
		if (type == 'group' || type == 'groupEdit' || type == 'transfGroup') {
			dropdownGroup.value = !dropdownGroup.value;
		}
		if (type == 'team' || type == 'teamEdit' || type == 'groupTeamEdit' || type == 'addTeam' || type == 'transfTeam') {
			dropdownGroupE.value = !dropdownGroupE.value;
		}
		if (type == 'transfUser') {
			dropdownTransf.value = !dropdownTransf.value;
		}
		if (type == 'marcasDropdown') {
			dropdownMarca.value = !dropdownMarca.value;
		}
		if (type == 'ufsDropdown') {
			dropdownUfs.value = !dropdownUfs.value;
		}
		if (type == 'ufsCompanyDropdown') {
			companyDropdownUfs.value = !companyDropdownUfs.value;
		}
		if (type == 'vendedorResponsavelDropdown') {
			vendedorResponsavelDropdown.value = !vendedorResponsavelDropdown.value;
		}
		if (type == 'anos') {
			dropdownAno.value = !dropdownAno.value;
		}
		if (type == 'combustiveis') {
			dropdownCombustivel.value = !dropdownCombustivel.value;
		}
		if (type == 'modelos') {
			dropdownModelo.value = !dropdownModelo.value;
		}
		if (type == 'versoes') {
			dropdownVersao.value = !dropdownVersao.value;
		}
		if (type == 'cores') {
			dropdownCores.value = !dropdownCores.value;
		}
		if (type == 'carrocerias') {
			dropdownCarroceria.value = !dropdownCarroceria.value;
		}
		if (type == 'transmissoes') {
			dropdownTransmissao.value = !dropdownTransmissao.value;
		}
		if (type == 'portas') {
			portasDropdown.value = !portasDropdown.value;
		}
		if (type == 'filtroMarcas') {
			marcaDropdownOpen.value = !marcaDropdownOpen.value;
		}
		if (type == 'filtroAgruparPor') {
			agrupaPorDropdownOpen.value = !agrupaPorDropdownOpen.value;
		}
		if (type == 'filtroEquipeRelatorios') {
			equipeRelatoriosDropdownOpen.value = !equipeRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroGrupoRelatorios') {
			grupoRelatoriosDropdownOpen.value = !grupoRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroUsuarioRelatorios') {
			usuarioRelatoriosDropdownOpen.value = !usuarioRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroTipoVeiculoRelatorios') {
			tipoVeiculoRelatoriosDropdownOpen.value = !tipoVeiculoRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroClassificacaoMetalRelatorios') {
			classificacaoMetalRelatoriosDropdownOpen.value = !classificacaoMetalRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroStatusRelatorios') {
			statusRelatoriosDropdownOpen.value = !statusRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroTipoNegociacaoRelatorios') {
			tipoNegociacaoRelatoriosDropdownOpen.value = !tipoNegociacaoRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroTipoAvaliacaoRelatorios') {
			tipoAvaliacaoRelatoriosDropdownOpen.value = !tipoAvaliacaoRelatoriosDropdownOpen.value;
		}
		if (type == 'filtroModelos') {
			modeloDropdownOpen.value = !modeloDropdownOpen.value;
		}
		if (type == 'filtroPlacas') {
			placaDropdownOpen.value = !placaDropdownOpen.value;
		}
		if (type == 'filtroEquipes') {
			equipeDropdownOpen.value = !equipeDropdownOpen.value;
		}
		if (type == 'filtroGrupos') {
			grupoDropdownOpen.value = !grupoDropdownOpen.value;
		}
		if (type == 'filtroUsuarios') {
			usuarioDropdownOpen.value = !usuarioDropdownOpen.value;
		}
		if (type == 'filtroStatus') {
			statusDropdownOpen.value = !statusDropdownOpen.value;
		}
		if (type == 'filtroRenegociado') {
			renegociadoDropdownOpen.value = !renegociadoDropdownOpen.value;
		}
		if (type == 'filtroAnosModelos') {
			anoModeloDropdownOpen.value = !anoModeloDropdownOpen.value;
		}
	}
}
watch(props, (val) => {
	if (val.type == 'modelos') {
		filteredValuesModelos.value = val.values;
	}
	if (val.type == 'versoes') {
		filteredValuesVersoes.value = val.values;
	}
});
function chooseItem(item?: string, itemId?: any, index?: number, type?: string, aditionalItem?: any, extraItem?: any) {
	if (type == 'job') {
		newUserJob.value = item ?? '';
		newUserJobId.value = itemId ?? 0;
	}
	if (type == 'jobEdit') {
		editUserJob.value = item ?? '';
		editUserJobId.value = itemId ?? 0;
	}
	if (type == 'group') {
		newUserGroup.value = item ?? '';
		newUserGroupId.value = itemId ?? 0;
	}
	if (type == 'groupEdit') {
		editUserGroup.value = item ?? '';
		editUserGroupId.value = itemId ?? 0;
	}
	if (type == 'team') {
		newUserTeam.value = item ?? '';
		newUserTeamId.value = itemId ?? 0;
		newUserGroup.value = '';
		newUserGroupId.value = 0;
	}
	if (type == 'teamEdit') {
		editUserTeam.value = item ?? '';
		editUserTeamId.value = itemId ?? 0;
		editUserGroup.value = '';
		editUserGroupId.value = 0;
	}
	if (type == 'transfTeam') {
		transfUserTeam.value = item ?? '';
		transfUserTeamId.value = itemId ?? 0;
		transfUserGroup.value = '';
		transfUserGroupId.value = 0;
		transfUserName.value = '';
		transfUserSurname.value = '';
		transfUserId.value = 0;
	}
	if (type == 'transfGroup') {
		transfUserGroup.value = item ?? '';
		transfUserGroupId.value = itemId ?? 0;
		transfUserName.value = '';
		transfUserSurname.value = '';
		transfUserId.value = 0;
	}
	if (type == 'transfUser') {
		transfUserName.value = item ?? '';
		transfUserSurname.value = aditionalItem ?? '';
		transfUserId.value = itemId ?? 0;
	}
	if (type == 'groupTeamEdit') {
		editTeam.value = item ?? '';
		teamId.value = itemId ?? 0;
	}
	if (type == 'addTeam') {
		newTeamGroup.value = item ?? '';
		newTeamGroupId.value = itemId ?? 0;
	}
	if (type == 'marcasDropdown') {
		clickMarca.value = true;
		marca.value = '';
		marcaId.value = 0;
		setTimeout(() => {
			marca.value = item ?? '';
			marcaId.value = itemId ?? 0;
			anoModelo.value = '';
			id_combustivel.value = 0;
			combustivel.value = '';
			combustivelId.value = 0;
			modelo.value = '';
			modeloId.value = 0;
			versao.value = '';
			idVersao.value = 0;
			cor.value = '';
			idCor.value = 0;
			anoFabricacao.value = '';
			dropdownAno.value = false;
			dropdownCombustivel.value = false;
			dropdownModelo.value = false;
			dropdownVersao.value = false;
			setTimeout(() => {
				clickMarca.value = false;
			}, 1000);
		}, 200);
	}
	if (type == 'anos') {
		anoModelo.value = item ?? '';
		id_combustivel.value = itemId ?? 0;
		combustivel.value = '';
		combustivelId.value = 0;
		modelo.value = '';
		modeloId.value = 0;
		versao.value = '';
		idVersao.value = 0;
		dropdownCombustivel.value = false;
		dropdownModelo.value = false;
		dropdownVersao.value = false;
	}
	if (type == 'combustiveis') {
		combustivel.value = item ?? '';
		combustivelId.value = 0;
		setTimeout(() => {
			combustivelId.value = itemId ?? 0;
			modelo.value = '';
			modeloId.value = 0;
			versao.value = '';
			idVersao.value = 0;
			dropdownModelo.value = false;
			dropdownVersao.value = false;
		}, 200);

	}
	if (type == 'modelos') {
		clickModelo.value = true;
		modelo.value = item ?? '';
		modeloId.value = 0;
		setTimeout(() => {
			modeloId.value = itemId ?? 0;
			versao.value = '';
			idVersao.value = 0;
			dropdownVersao.value = false;
			setTimeout(() => {
				clickModelo.value = false;
			}, 1000);
		}, 200);
	}
	if (type == 'versoes') {
		clickVersao.value = true;
		versao.value = item ?? '';
		idVersao.value = itemId ?? 0;
		id_fipe.value = itemId ?? 0;
		codigo_fipe.value = aditionalItem;
		eletricoAval.value = extraItem ?? false;
		
		setTimeout(() => {
			clickVersao.value = false;
		}, 1200);
	}
	if (type == 'cores') {
		cor.value = item ?? '';
		idCor.value = itemId ?? 0;
	}
	if (type == 'carrocerias') {
		carroceria.value = item ?? '';
		carroceriaId.value = itemId ?? 0;
	}
	if (type == 'transmissoes') {
		transmissao.value = item ?? '';
		transmissaoId.value = itemId ?? 0;
	}
	if (type == 'portas') {
		portas.value = item ?? '';
		portasId.value = itemId ?? 0;
	}
	if (type == 'filtroMarcas') {
		marcaFilter.value = item ?? '';
		marcaFilterClicked.value = true;
	}
	if (type == 'filtroModelos') {
		modeloFilter.value = item ?? '';
		modeloFilterClicked.value = true;
	}
	if (type == 'filtroPlacas') {
		placaFilter.value = item ?? '';
		placaFilterClicked.value = true;
	}
	if (type == 'filtroEquipes') {
		equipeFilter.value = item ?? '';
		equipeFilterClicked.value = true;
	}
	if (type == 'filtroGrupos') {
		grupoFilter.value = item ?? '';
		grupoFilterClicked.value = true;
	}
	if (type == 'filtroUsuarios') {
		usuarioFilter.value = item ?? '';
		usuarioFilterClicked.value = true;
	}
	if (type == 'filtroStatus') {
		statusFilter.value = item ?? '';
		statusFilterClicked.value = true;
	}
	if (type == 'filtroRenegociado') {
		renegociadoFilter.value = item ?? '';
		renegociadoFilterCliked.value = true;
	}
	if (type == 'filtroAnosModelos') {
		anoModeloFilter.value = item ?? '';
		anoModeloFilterClicked.value = true;
	}
	if (type == 'ufsDropdown') {
		uf.value = item ?? '';
		ufId.value = itemId ?? 0;
	}
	if (type == 'ufsCompanyDropdown') {
		companyUf.value = item ?? '';
		companyUfId.value = itemId ?? 0;
	}
	if (type == 'vendedorResponsavelDropdown') {
		vendedorResponsavel.value = item ?? '';
		vendedorResponsavelId.value = itemId ?? 0;
	}
	if (type == 'filtroAgruparPor') {
		agruparPor.value = item ?? '';
	}
	if (type == 'filtroEquipeRelatorios') {
		equipeRelatorios.value = item ?? '';
	}
	if (type == 'filtroGrupoRelatorios') {
		grupoRelatorios.value = item ?? '';
	}
	if (type == 'filtroUsuarioRelatorios') {
		usuarioRelatorios.value = item ?? '';
	}
	if (type == 'filtroTipoVeiculoRelatorios') {
		tipoVeiculoRelatorios.value = item ?? '';
	}
	if (type == 'filtroClassificacaoMetalRelatorios') {
		classificacaoMetalRelatorios.value = item ?? '';
	}
	if (type == 'filtroStatusRelatorios') {
		statusRelatorios.value = item ?? '';
	}
	if (type == 'filtroTipoNegociacaoRelatorios') {
		tipoNegociacaoRelatorios.value = item ?? '';
	}
	if (type == 'filtroTipoAvaliacaoRelatorios') {
		tipoAvaliacaoRelatorios.value = item ?? '';
	}
}
</script>

<template>
	<div class="dropdownWrapper" :class="{ dropdownAllWidth: allWidth == true }">
		<div class="label-title" style="flex-direction: column;align-items: flex-start;justify-content: center;">
			<h1>
				<slot name="label"></slot>
			</h1>
			<p>
				<slot name="auxiliarText"></slot>
			</p>
		</div>
		<div class="dropdown" @click="openAndCloseModal(type)" :class="{
		dropdownBorder: border == true,
		dropdownError: error == true,
		dropdownInactive: deactive == true,
		dropdownOpen: dropdownMarca == true || dropdownAno == true || dropdownCombustivel == true || marcaDropdownOpen == true || modeloDropdownOpen == true || placaDropdownOpen == true || anoModeloDropdownOpen == true || vendedorResponsavelDropdown == true
	}">
			<div class="label" :class="{ dropdownDeactive: deactive == true }">
				<h1
					v-if="valueDropdown.length > 0 && type !== 'marcasDropdown' && type !== 'modelos' && type !== 'versoes' && type !== 'ufsDropdown' && type !== 'ufsCompanyDropdown' && type !== 'filtroMarcas' && type !== 'filtroModelos' && type !== 'filtroPlacas' && type !== 'filtroEquipes' && type !== 'filtroGrupos' && type !== 'filtroUsuarios' && type !== 'filtroAnosModelos'">
					{{ valueDropdown }}
				</h1>
				<p
					v-if="valueDropdown.length <= 0 && type !== 'marcasDropdown' && type !== 'modelos' && type !== 'versoes' && type !== 'ufsDropdown' && type !== 'ufsCompanyDropdown' && type !== 'filtroMarcas' && type !== 'filtroModelos' && type !== 'filtroPlacas' && type !== 'filtroEquipes' && type !== 'filtroGrupos' && type !== 'filtroUsuarios' && type !== 'filtroAnosModelos'">
					{{ placeholder }}
				</p>
				<input v-if="type == 'marcasDropdown'" type="text" v-model="marca" @input="searchOnValuesMarcas()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'modelos'" type="text" v-model="modelo" @input="searchOnValuesModelos()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'versoes'" type="text" v-model="versao" @input="searchOnValuesVersoes()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'ufsDropdown'" type="text" v-model="uf" @input="searchOnValuesUfs()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'ufsCompanyDropdown'" type="text" v-model="companyUf" @input="searchOnValuesUfsCompany()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroMarcas'" type="text" v-model="marcaFilter" @input="searchOnValuesMarcasFilter()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroModelos'" type="text" v-model="modeloFilter" @input="searchOnValuesModelosFilter()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroPlacas'" type="text" v-model="placaFilter" @input="searchOnValuesPlacasFilter()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroEquipes'" type="text" v-model="equipeFilter" @input="searchOnValuesEquipesFilter()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroGrupos'" type="text" v-model="grupoFilter" @input="searchOnValuesGruposFilter()"
					:placeholder="placeholder" :disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroUsuarios'" type="text" v-model="usuarioFilter"
					@input="searchOnValuesUsuariosFilter()" :placeholder="placeholder"
					:disabled="search == true && values.length > 0 ? false : true" />
				<input v-if="type == 'filtroAnosModelos'" type="number" v-model="anoModeloFilter"
					@input="searchOnValuesAnosModelosFilter()" :placeholder="placeholder"
					:disabled="search == true && values.length > 0 ? false : true" maxlength="4" />
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
					:class="{ rotated: deactive == true ? false : open }">
					<path d="M4 16L12 8L20 16" stroke="#BEBEC2" stroke-width="1.5" stroke-linecap="round"
						stroke-linejoin="round" />
				</svg>
			</div>
			<TransitionGroup name="dropdownAnimation">
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownJob && (type == 'job' || type == 'jobEdit') && deactive == false"
					@click="chooseItem(item.cargo, item.id_cargo, index, type)">
					<p>{{ item.cargo }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownGroup && (type == 'group' || type == 'groupEdit' || type == 'transfGroup') && deactive == false"
					@click="chooseItem(item.grupo, item.id_grupo, index, type)">
					<p>{{ item.grupo }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownGroupE && (type == 'team' || type == 'teamEdit' || type == 'groupTeamEdit' || type == 'addTeam' || type == 'transfTeam') && deactive == false"
					@click="chooseItem(item.equipe, item.id_equipe, index, type)">
					<p>{{ item.equipe }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownTransf && type == 'transfUser' && deactive == false"
					@click="chooseItem(item.nome, item.id_usuario, index, type, item.sobrenome)">
					<p>{{ item.nome }}</p>
				</div>
				<div class="items" v-for="(item, index) in filteredValuesMarcas.length > 0 ? filteredValuesMarcas : values"
					v-if="dropdownMarca && type == 'marcasDropdown' && deactive == false"
					@click="chooseItem(item.marca, item.id_marca, index, type)" :style="{
		zIndex: (filteredValuesMarcas.length > 0 ? filteredValuesMarcas.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item.marca }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesMarcasFilter.length > 0 ? filteredValuesMarcasFilter.slice(0, 10) : values.slice(0, 10)"
					v-if="marcaDropdownOpen && type == 'filtroMarcas' && deactive == false"
					@click="chooseItem(item, item.id_marca, index, type)" :style="{
		zIndex: (filteredValuesMarcasFilter.length > 0 ? filteredValuesMarcasFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesModelosFilter.length > 0 ? filteredValuesModelosFilter.slice(0, 10) : values.slice(0, 10)"
					v-if="modeloDropdownOpen && type == 'filtroModelos' && deactive == false"
					@click="chooseItem(item, item.id_modelo, index, type)" :style="{
		zIndex: (filteredValuesModelosFilter.length > 0 ? filteredValuesModelosFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesPlacasFilter.length > 0 ? filteredValuesPlacasFilter.slice(0, 10) : values.slice(0, 10)"
					v-if="placaDropdownOpen && type == 'filtroPlacas' && deactive == false"
					@click="chooseItem(item, item.id_placa, index, type)" :style="{
		zIndex: (filteredValuesPlacasFilter.length > 0 ? filteredValuesPlacasFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesEquipesFilter.length > 0 ? filteredValuesEquipesFilter : values"
					v-if="equipeDropdownOpen && type == 'filtroEquipes' && deactive == false"
					@click="chooseItem(item, item.id_equipes, index, type)" :style="{
		zIndex: (filteredValuesEquipesFilter.length > 0 ? filteredValuesEquipesFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesGruposFilter.length > 0 ? filteredValuesGruposFilter : values"
					v-if="grupoDropdownOpen && type == 'filtroGrupos' && deactive == false"
					@click="chooseItem(item, item.id_grupo, index, type)" :style="{
		zIndex: (filteredValuesGruposFilter.length > 0 ? filteredValuesGruposFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesUsuariosFilter.length > 0 ? filteredValuesUsuariosFilter : values"
					v-if="usuarioDropdownOpen && type == 'filtroUsuarios' && deactive == false"
					@click="chooseItem(item, item.id_usuario, index, type)" :style="{
		zIndex: (filteredValuesUsuariosFilter.length > 0 ? filteredValuesUsuariosFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesAnosModelosFilter.length > 0 ? filteredValuesAnosModelosFilter : values"
					v-if="anoModeloDropdownOpen && type == 'filtroAnosModelos' && deactive == false"
					@click="chooseItem(item, item.id_ano_modelo, index, type)" :style="{
		zIndex: (filteredValuesAnosModelosFilter.length > 0 ? filteredValuesAnosModelosFilter.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="statusDropdownOpen && type == 'filtroStatus' && deactive == false"
					@click="chooseItem(item, item.id_status, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="renegociadoDropdownOpen && type == 'filtroRenegociado' && deactive == false"
					@click="chooseItem(item, item.id_status, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in filteredValuesUfs.length > 0 ? filteredValuesUfs : values"
					v-if="dropdownUfs && type == 'ufsDropdown' && deactive == false"
					@click="chooseItem(item.uf, item.id_uf, index, type)" :style="{
		zIndex: (filteredValuesUfs.length > 0 ? filteredValuesUfs.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item.uf }}</p>
				</div>
				<div class="items"
					v-for="(item, index) in filteredValuesUfsCompany.length > 0 ? filteredValuesUfsCompany : values"
					v-if="companyDropdownUfs && type == 'ufsCompanyDropdown' && deactive == false"
					@click="chooseItem(item.uf, item.id_uf, index, type)" :style="{
		zIndex: (filteredValuesUfsCompany.length > 0 ? filteredValuesUfsCompany.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item.uf }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="vendedorResponsavelDropdown && type == 'vendedorResponsavelDropdown' && deactive == false"
					@click="chooseItem(item.nome, item.id_usuario, index, type)" :style="{ zIndex: values.length - index + 1 }">
					<p>{{ item.nome }}</p>
				</div>
				<div class="items" v-for="(item, index) in values" v-if="dropdownAno && type == 'anos' && deactive == false"
					@click="chooseItem(item.ano, item.id_combustivel, index, type)"
					:style="{ zIndex: values.length - index + 1 }">
					<p>{{ item.ano }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownCombustivel && type == 'combustiveis' && deactive == false"
					@click="chooseItem(item.combustivel, item.id_combustivel, index, type)"
					:style="{ zIndex: values.length - index + 1 }">
					<p>{{ item.combustivel }}</p>
				</div>
				<div class="items" v-for="(item, index) in filteredValuesModelos.length > 0 ? filteredValuesModelos : values"
					v-if="dropdownModelo && type == 'modelos' && deactive == false"
					@click="chooseItem(item.modelo, item.id_ano_combustivel, index, type)" :style="{
		zIndex: (filteredValuesModelos.length > 0 ? filteredValuesModelos.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item.modelo }}</p>
				</div>
				<div class="items" v-for="(item, index) in filteredValuesVersoes.length > 0 ? filteredValuesVersoes : values"
					v-if="dropdownVersao && type == 'versoes' && deactive == false"
					@click="chooseItem(item.versao, item.id_fipe, index, type, item.codigo, item.eletrico)" :style="{
		zIndex: (filteredValuesVersoes.length > 0 ? filteredValuesVersoes.length - 1 - index : values.length - index) + 1
	}">
					<p>{{ item.versao }}</p>
				</div>
				<div class="items" v-for="(item, index) in values" v-if="dropdownCores && type == 'cores' && deactive == false"
					@click="chooseItem(item.cor, item.id_cor, index, type)">
					<p>{{ item.cor }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownCarroceria && type == 'carrocerias' && deactive == false"
					@click="chooseItem(item.carroceria, item.id_carroceria, index, type)">
					<p>{{ item.carroceria }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="dropdownTransmissao && type == 'transmissoes' && deactive == false"
					@click="chooseItem(item.transmissao, item.id_transmissao, index, type)">
					<p>{{ item.transmissao }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="portasDropdown && type == 'portas' && deactive == false"
					@click="chooseItem(item.portas, item.id_portas, index, type)">
					<p>{{ item.portas }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="agrupaPorDropdownOpen && type == 'filtroAgruparPor' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="equipeRelatoriosDropdownOpen && type == 'filtroEquipeRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="grupoRelatoriosDropdownOpen && type == 'filtroGrupoRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="usuarioRelatoriosDropdownOpen && type == 'filtroUsuarioRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="tipoVeiculoRelatoriosDropdownOpen && type == 'filtroTipoVeiculoRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="classificacaoMetalRelatoriosDropdownOpen && type == 'filtroClassificacaoMetalRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="statusRelatoriosDropdownOpen && type == 'filtroStatusRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="tipoNegociacaoRelatoriosDropdownOpen && type == 'filtroTipoNegociacaoRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
				<div class="items" v-for="(item, index) in values"
					v-if="tipoAvaliacaoRelatoriosDropdownOpen && type == 'filtroTipoAvaliacaoRelatorios' && deactive == false"
					@click="chooseItem(item, item, index, type)">
					<p>{{ item }}</p>
				</div>
			</TransitionGroup>
		</div>
	</div>
</template>
