<script setup lang="ts">
import buttonVue from './MOBILE/buttons/button.vue';
import { storeToRefs } from 'pinia';
import { OnClickOutside } from '@vueuse/components';
import { useRequestEvaluation } from '../stores/requestEvaluation';
import router from '@/router';
const requestEvalution = useRequestEvaluation();
const { comentarioSolicita, idSolicitacao, modelSolicitaOpen, modalAvaliaSolicitacaoOpen, placaSolicita, chaveReservaCliente, documentExerciseSolicita, emailClienteSolicita, garantiaFabricaCliente, garantiaFabricaDataCliente, manualCliente, nomeClienteSolicita, revisaoFabricaCliente, revisaoFabricaDataCliente, revisaoFabricaKm, telefoneClienteSolicita, tipoNegociacaoCliente, isEvaluation, vendedorResponsavel } = storeToRefs(requestEvalution);
const closeModal = () => {
  requestEvalution.$reset();
};
const avaliaSolicitacao = () => {
  modalAvaliaSolicitacaoOpen.value = false;
  isEvaluation.value = true;
  router.push({ name: 'novaAvaliacao' })
}
function formatData(data: string, withHours: boolean) {
  const date = new Date(data);
  if (withHours === true) {
    return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'America/Sao_Paulo' });
  } else {
    return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }
}
const tipos_Negociacao = ['Troca por veículo novo','Troca por veículo seminovo', 'Apenas venda','Passagem oficina' ]
</script>


<template>
  <OnClickOutside @trigger="closeModal()" class="clickOutside">
    <div class="modalAvaliaSolicitacaoAval">
      <div class="infoModal">
        <div class="titulo">
          <div class="label">
            <h1>Avaliar avaliação</h1>
          </div>
          <div class="icon" @click="closeModal()">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.65685 6.65711L9.88934 9.8896L12.3137 12.314M12.3137 12.314L6.65685 17.9708M12.3137 12.314L17.9706 17.9708M12.3137 12.314L17.9706 6.65711"
                stroke="#63636E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="conteudoModal">
          <div class="inputGroup" v-if="nomeClienteSolicita !== ''">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Nome do cliente</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ nomeClienteSolicita }}</h1>
          </div>
          <div class="inputGroup" v-if="telefoneClienteSolicita !== ''">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Telefone do cliente</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ telefoneClienteSolicita }}</h1>
          </div>
          <div class="inputGroup" v-if="emailClienteSolicita !== ''">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">E-mail do cliente</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ emailClienteSolicita }}</h1>
          </div>
          <div class="inputGroup" v-if="vendedorResponsavel !== ''">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Vendedor responsável</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ vendedorResponsavel }}</h1>
          </div>
          <div class="inputGroup">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Placa</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ placaSolicita == null ? '-' : placaSolicita }}</h1>
          </div>
          <div class="inputGroup" v-if="documentExerciseSolicita !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Qual o ano de exercício do documento?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ documentExerciseSolicita }}</h1>
          </div>
          <div class="inputGroup" v-if="manualCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Possui manual de manutenção?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ manualCliente == 1 ? 'Sim' : 'Não' }}</h1>
          </div>
          <div class="inputGroup" v-if="chaveReservaCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Possui chave reserva?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ chaveReservaCliente == 1 ? 'Sim' : 'Não' }}</h1>
          </div>
          <div class="inputGroup" v-if="garantiaFabricaCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Está em garantia de fábrica?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ garantiaFabricaCliente == 1 ? 'Sim' : 'Não' }}</h1>
          </div>
          <div class="inputGroup" v-if="garantiaFabricaDataCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Até quando vai a garantia de fábrica?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ formatData(garantiaFabricaDataCliente, false) }}</h1>
          </div>
          <div class="inputGroup" v-if="revisaoFabricaCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Todas as revisões estão feitas?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ revisaoFabricaCliente == 1 ? 'Sim' : 'Não' }}</h1>
          </div>
          <div class="inputGroup" v-if="revisaoFabricaDataCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Quando foi feita a última revisão?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ formatData(revisaoFabricaDataCliente, false) }}</h1>
          </div>
          <div class="inputGroup" v-if="revisaoFabricaKm !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Qual a km da última revisão?</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ revisaoFabricaKm.toLocaleString('pt-BR') }} km</h1>
          </div>
          <div class="inputGroup" v-if="tipoNegociacaoCliente !== null">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Tipo de negociação</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ tipos_Negociacao[tipoNegociacaoCliente] }}</h1>
          </div>
          <div class="inputGroup">
            <div class="label" style="align-items: flex-start;padding: 0px var(--padding-m);gap: 4px;">
              <h1 style="font: var(--b-s-Ag-5);color: var(--cr-p-azulEscuro)">Comentário</h1>
            </div>
            <h1 class="comentarioSolicitacao">{{ comentarioSolicita == '' ? '-' : comentarioSolicita }}</h1>
          </div>
        </div>
        <div class="buttonWrapper" style="padding: var(--padding-xl);position: relative;">
          <div class="button" style="background: var(--cr-p-azulMedio);" @click="avaliaSolicitacao()">
            <p>Continuar para avaliação</p>
          </div>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>