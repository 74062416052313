<script setup lang="ts">
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';
import { OnClickOutside } from '@vueuse/components';
import axios from 'axios';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useEvaluationView } from '../stores/evaluationView';
import { useLogin } from '../stores/login';
const viewEvaluation = useEvaluationView();
const userLogin = useLogin();
const { modalEditaValor, editValorFinal, errorValorFinal, errorTextValorFinal } = storeToRefs(viewEvaluation);
const { id_empresa, notificacoesAtivas, modalDesvioPagamento, errorDesvioPagamento, errorTextDesvioPagamento } = storeToRefs(userLogin);

const clickOutside = () => {
	modalDesvioPagamento.value = false;
};
const closeModal = () => {
	modalDesvioPagamento.value = false;
};
const buttonActive = ref(false);

watch(notificacoesAtivas.value, (val) => {
	const item = val.find(item => item.id_notificacao_possivel === 4);
	const formatPercentage = (value: string) => {
		const floatValue = parseFloat(value);
		return Number.isInteger(floatValue) ? floatValue.toFixed(0) : floatValue.toFixed(2);
	};
	if (item) {
		if (item.variavel.length > 0) {
			const itemNumber = item.variavel.replace('%', '').replace(',', '.');
			buttonActive.value = parseFloat(formatPercentage(itemNumber)) > 0;
		} else {
			buttonActive.value = false;
		}
	} else {
		buttonActive.value = false;
	}

}, { immediate: true });

</script>

<template>
	<div class="modalAddUserDesktop">
		<div class="tituloModalAddUserDesktop">
			<div class="label">
				<h1>Desativar usuário</h1>
			</div>
			<div class="iconRight">
				<svg @click="closeModal()" width="24" height="24" viewBox="0 0 24 24" fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.65685 6.65687L9.88934 9.88935L12.3137 12.3137M12.3137 12.3137L6.65685 17.9706M12.3137 12.3137L17.9706 17.9706M12.3137 12.3137L17.9706 6.65687"
						stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</div>
		</div>
		<div class="track400"></div>
		<div class="contentAddUserDesktop" >
			<div class="inputWrapperAddUserDesktopTracker" style="width: 100%;height: 100%;display:flex;flex-direction: column;justify-content: space-between;align-items: flex-end;">
				<div class="inputWrapperAddUserDesktop">
					<inputGroup :disabled="false" :placeholder="'%'" :type="'editDesvioPagamento'" :error="errorDesvioPagamento" :border="true">
						<template #label>Percentual de desvio</template>
						<template #auxiliarText>Receber notificações com desvios maior que o apontado</template>
					</inputGroup>
					<div class="error" v-if="errorDesvioPagamento">
						<p>
							{{ errorTextDesvioPagamento }}
						</p>
					</div>
				</div>
				<div class="botao">
					<div class="buttonClicker">
						<buttonVue :type="buttonActive == true ? 'active' : 'custom'" :action="'saveChangesDesvioPagamento'"
							:color="'deactive'">
							<template #labelButton>Salvar alterações</template>
						</buttonVue>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
