<script setup lang="ts">
import { useLogin } from '../stores/login';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
const login = useLogin();
const { notificacoesAtivas, modalDesvioPagamento } = storeToRefs(login);
const clickAcess = (index: number) => {
	if (notificacoesAtivas.value[index].ativo == 1) {
		notificacoesAtivas.value[index].ativo = 0;
	} else {
		notificacoesAtivas.value[index].ativo = 1;
		if (notificacoesAtivas.value[index].variavel !== null) {
			modalDesvioPagamento.value = true;
		}
	}
};

const props = defineProps<{
	checked: number;
	index: number;
	small?: boolean;
}>();
</script>

<template>
	<div class="toggle" @click="clickAcess(index)">
		<div class="label" style="justify-content: center;">
			<h1 v-if="$slots.acessLabel">
				<slot name="acessLabel"></slot>
			</h1>
			<h2 v-if="$slots.acessDesc">
				<slot name="acessDesc"></slot>
			</h2>
			<h3 v-if="$slots.dateAcess">
				<slot name="dateAcess"></slot>
			</h3>
		</div>
		<label class="toggle-switch" :class="{ toggleSwitchDesktop: small == true }">
			<input type="checkbox" :checked="checked == 1 ? true : false" disabled />
			<div class="toggle-switch-background">
				<div class="toggle-switch-handle"></div>
			</div>
		</label>
	</div>
</template>
