<script setup lang="ts">
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';
import { OnClickOutside } from '@vueuse/components';
import axios from 'axios';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useEvaluationView } from '../stores/evaluationView';
import { useLogin } from '../stores/login';
const viewEvaluation = useEvaluationView();
const userLogin = useLogin();
const { modalEditaValor, editValorFinal, errorValorFinal, errorTextValorFinal, modalHistorico, historico_alteracoes } = storeToRefs(viewEvaluation);
const { id_empresa } = storeToRefs(userLogin);

function formatData(data: string, withHours: boolean) {
	const date = new Date(data);
	if (withHours === true) {
		return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, timeZone: 'America/Sao_Paulo' });
	} else {
		return date.toLocaleString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
	}
}
function formatarValor(texto: string) {
	const regex = /R\$(\d+)/;
	const match = texto.match(regex);
	if (match) {
		const valor = parseInt(match[1]);
		return `R$ ${valor.toLocaleString('pt-BR')}`;
	} else {
		return 'Valor não encontrado';
	}
}

const clickOutside = () => {
	modalHistorico.value = false;
};
const closeModal = () => {
	modalHistorico.value = false;
};
</script>


<template>
	<OnClickOutside @trigger="clickOutside()" class="clickOutside">
		<div class="modalHistoricoAlteracoes">
			<div class="body">
				<div class="titulo">
					<div class="label">
						<h1>Histórico</h1>
					</div>
					<div class="icon" @click="closeModal">
						<img src="/src/assets/icons/x.svg" />
					</div>
				</div>
				<div class="content">
					<div class="listaInformativa" v-for="(item, index) in historico_alteracoes" :key="index"
						v-if="historico_alteracoes.length > 0">
						<h1 v-if="item.alteracao.startsWith('Novo preço')">
							{{ item.alteracao.startsWith('Novo preço') ? `Novo preço: ${formatarValor(item.alteracao)}` : item.alteracao }}
						</h1>
						<h1 v-else-if="item.alteracao.startsWith('Novo valor solicitado')">
							{{ item.alteracao.startsWith('Novo valor solicitado') ? `Novo valor solicitado: ${formatarValor(item.alteracao)}` : item.alteracao }}
						</h1>
						<h1 v-else>
							{{ item.alteracao }}
						</h1>
						<h2>Data: {{ formatData(item.data_alteracao, true) }}</h2>
						<h2>Usuario: {{ item.usuario }}</h2>
						<h2 v-if="item.comentario">{{ item.comentario }}</h2>
					</div>
					<div v-else
						style="width: 100%;display: flex; flex-direction: column; align-items: center; justify-content: center; padding: var(--padding-l);">
						<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M54.75 32C54.75 44.1503 44.9003 54 32.75 54C20.5997 54 10.75 44.1503 10.75 32C10.75 19.8497 20.5997 10 32.75 10C44.9003 10 54.75 19.8497 54.75 32ZM15.15 32C15.15 41.7202 23.0298 49.6 32.75 49.6C42.4702 49.6 50.35 41.7202 50.35 32C50.35 22.2798 42.4702 14.4 32.75 14.4C23.0298 14.4 15.15 22.2798 15.15 32Z"
								fill="#bebec2" />
							<path d="M23.75 32L29.75 38L41.75 26" stroke="#bebec2" stroke-width="4" stroke-linecap="round"
								stroke-linejoin="round" />
						</svg>
						<h1 style="font: var(--b-r-Ag-4);color: var(--cr-c-400);">Nenhum item nesta lista</h1>
					</div>
				</div>
			</div>
		</div>
	</OnClickOutside>
</template>