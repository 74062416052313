import { defineStore } from 'pinia';

export const useControlAcessUser = defineStore('controlAcessUser', {
	state: () => ({
		userName: '',
		userSurname: '',
		userJob: '',
		userEmail: '',
		userId: 0,
		acessosControl: [] as any,
		refreshListAcess: false,
		clickedAcess: false,
		clickedResetAcess: false
	})
});
