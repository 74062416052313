import { defineStore } from 'pinia';

export const useSplash = defineStore('splash', {
	state: () => ({
		rodando: false,
		show: true,
		loadingContent: false,
		rodou: false
	})
});
