import { defineStore } from 'pinia';

export const useForgotPassword = defineStore('forgotPassword', {
	state: () => ({
		emailForgot: '',
		errorDesc: '',
		errorForgot: false,
		clickButtonForgot: false,
		haveAErrorForgotPassword: false,
		newPasswordForgot: '',
		confirmPasswordForgot: '',
		passwordForgotValid: true,
		passwordTokenForgot: ''
	})
});
