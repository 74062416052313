import { defineStore } from 'pinia';
interface PerguntasAvaliacoes {
	ordem: number;
	pergunta: string;
	resposta_tipo: string;
	respostas: Array<{
		ordem: number;
		resposta: string;
		resposta_descricao: string;
		valor_sugerido: number | null;
	}>;
}

export const useEvaluation = defineStore('evaluation', {
	state: () => ({
		tipoAvaliacao: 0,
		km: '' as string,
		kmError: false,
		kmErrorText: '',
		documentExercise: '',
		documentExerciseError: false,
		documentExerciseErrorText: '',
		manual: '',
		chaveReserva: '',
		garantiaFabrica: '',
		garantiaData: '' as any,
		revisao: '',
		revisaoData: '' as any,
		kmRevisao: '',
		kmRevisaoError: false,
		kmRevisaoErrorText: '',
		csv: '',
		registrosCsvs: [] as any,
		respostasHigienizacaoPolimento: [] as any,
		tipoNegociacao: 0,
		tipoNegociacaoIndex: null as null | number,
		currentStep: 0,
		respostasAvaliacao: [] as any,
		fotosAvaliacao: [] as any,
		avaliacaoCompletaCreate: false,
		avaliacaoRapidaCreate: false,
		convite: false as any,
		conviteValue: '',
		toTop: false,
		clickedSendEvaluation: false,
		perguntas: [] as PerguntasAvaliacoes[],
	}),
	actions: {
		decrementCurrentStep() {
			if (this.currentStep > 0) {
				this.currentStep -= 1;
			}
		}
	}
});