import { defineStore } from 'pinia';

export const useCompanyControl = defineStore('companyControl', {
	state: () => ({
		refreshListCompany: false,
		modalAddCompany: false,
		modalEditCompany: false,
		companyId: 0,
		editCompanyName: '',
		editAvaliacoes_mensais: '',
		clickButtonCompanyControl: false,
		companyName: '',
		companyTeamName: '',
		companyGroupName: '',
		companyAdminName: '',
		companyAdminSurname: '',
		companyAdminEmail: '',
		companyAdminPassword: '',
		companyAvaliacoesMensais: '',
		companyUf: '',
		companyUfId: 0,
		companyDropdownUfs: false,
		clickedAddNewCompany: false,
		errorAddCompany: false,
		errorTextAddCompany: ''
	})
});
