<script setup lang="ts">
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';
import { OnClickOutside } from '@vueuse/components';
import axios from 'axios';
import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useEvaluationView } from '../stores/evaluationView';
import { useLogin } from '../stores/login';
const viewEvaluation = useEvaluationView();
const userLogin = useLogin();
const { modalEditaValor, editValorFinal, errorValorFinal, errorTextValorFinal, valorTempoVendaNovo, valorHigienizacaoEsteticaNovo, valorLucroNovo, valorMargemNegociacaoNovo, valorMediaInternetNovo, valorProvisaoRevisoesNovo, valorReparosNovo, precificacao, indexButton, modalComentarioRenegociacao, typeComentario, comentarioRenegociacao } = storeToRefs(viewEvaluation);
const { id_empresa } = storeToRefs(userLogin);

const clickOutside = () => {
	modalComentarioRenegociacao.value = false;
	comentarioRenegociacao.value = '';
};
const closeModal = () => {
	modalComentarioRenegociacao.value = false;
	comentarioRenegociacao.value = '';
};
const buttonActive = ref(false);

watch(comentarioRenegociacao, (val) => {
	if (val.toString().length >= 10) {
		buttonActive.value = true;
		errorValorFinal.value = false;
		errorTextValorFinal.value = '';
	} else {
		errorValorFinal.value = true;
		errorTextValorFinal.value = 'O tamanho minimo de comentário são 10 caracteres';
		buttonActive.value = false;
	}
}, { immediate: true });

</script>


<template>
	<OnClickOutside @trigger="clickOutside()" class="clickOutside">
		<div class="modalEditComentarioRenegociacaoModal">
			<div class="body">
				<div class="titulo">
					<div class="label">
						<h1>Editar comentario</h1>
					</div>
					<div class="icon" @click="closeModal">
						<img src="/src/assets/icons/x.svg" />
					</div>
				</div>
				<div class="inputWrapper">
					<textarea :style="{ border: errorValorFinal == true ? '1px solid var(--cr-i-notificacao)' : '' }"
						name="mensagem" rows="6" maxlength="140" v-model="comentarioRenegociacao"></textarea>
					<h1 style="color: var(--cr-c-500);font: var(--b-r-Ag-6);padding: 0px var(--padding-m);">
						{{ comentarioRenegociacao.length }} / 140
					</h1>
					<div class="error" v-if="errorValorFinal">
						<p>
							{{ errorTextValorFinal }}
						</p>
					</div>
				</div>
				<div class="content">
					<buttonVue
						v-if="typeComentario !== 'aceitaRenegociacao' && typeComentario !== 'recusaRenegociacao' && typeComentario !== 'vetaRenegociacao'"
						:type="buttonActive == true ? 'active' : 'custom'" :action="'sendRenegociacao'" :color="'deactive'">
						<template #labelButton>Enviar renegociação</template>
					</buttonVue>
					<buttonVue v-if="typeComentario == 'aceitaRenegociacao'" :type="buttonActive == true ? 'confirm' : 'custom'"
						:action="'aceitarRenegociacao'" :color="'deactive'">
						<template #labelButton>Aceitar renegociação</template>
					</buttonVue>
					<buttonVue v-if="typeComentario == 'recusaRenegociacao'"
						:type="buttonActive == true ? 'destructiveFill' : 'custom'" :action="'recusarRenegociacao'"
						:color="'deactive'">
						<template #labelButton>Recusar renegociação</template>
					</buttonVue>
					<buttonVue v-if="typeComentario == 'vetaRenegociacao'"
						:type="buttonActive == true ? 'destructiveFill' : 'custom'" :action="'vetarRenegociacao'"
						:color="'deactive'">
						<template #labelButton>Vetar renegociação</template>
					</buttonVue>
				</div>
			</div>
		</div>
	</OnClickOutside>
</template>