import { defineStore } from 'pinia';

export const useFilters = defineStore('filters', {
  state: () => ({
    marcaFilter: '',
    marcaFilterClicked: false,
    modeloFilter: '',
    modeloFilterClicked: false,
    anoModeloFilter: '',
    anoModeloFilterClicked: false,
    placaFilter: '',
    placaFilterClicked: false,
    equipeFilter: '',
    equipeFilterClicked: false,
    grupoFilter: '',
    grupoFilterClicked: false,
    usuarioFilter: '',
    usuarioFilterClicked: false,
    blindagemFilter: '',
    blindagemClicked: false,
    tetoSolarFilter: '',
    tetoSolarFilterClicked: false,
    incluirIntegracoesFilter: '',
    incluirIntegracoesClicked: false,
    statusFilter: '',
    renegociadoFilter: '',
    renegociadoFilterCliked: false,
    statusFilterClicked: false,
    marcaDropdownOpen: false,
    modeloDropdownOpen: false,
    anoModeloDropdownOpen: false,
    placaDropdownOpen: false,
    equipeDropdownOpen: false,
    grupoDropdownOpen: false,
    usuarioDropdownOpen: false,
    statusDropdownOpen: false,
    renegociadoDropdownOpen: false,
    clickedResetFilters: false,
    loadingAvaliacoes: true,
    dateAll: [] as any,
    dataInicial: '',
    dataFinal: '',
    filtroAberto: false,
    isSeaching: false
  }) 
});
