import { defineStore } from 'pinia';

export const useControlUser = defineStore('controlUser', {
	state: () => ({
		modalAddUserOpen: false,
		modalEditUser: false,
		modalDeleteUser: false,
		modalControlAcessUser: false,
		dropdownJob: false,
		dropdownGroup: false,
		dropdownTransf: false,
		editUserId: 0,
		editUserEmail: '',
		editUserName: '',
		editUserSurname: '',
		editUserJob: '',
		editUserGroup: '',
		editUserTeam: '',
		editUserJobId: 0,
		editUserGroupId: 0,
		editUserTeamId: 0,
		transfUserTeam: '',
		transfUserTeamId: 0,
		transfUserGroup: '',
		transfUserGroupId: 0,
		transfUserName: '',
		transfUserSurname: '',
		transfUserId: 0,
		newUserEmail: '',
		newUserPassword: '',
		newUserName: '',
		newUserSurname: '',
		newUserJob: '',
		newUserJobId: 0,
		newUserTeam: '',
		newUserTeamId: 0,
		newUserGroup: '',
		newUserGroupId: 0,
		editUserIndexClicked: 0,
		deleteUserPassword: '',
		clickButtonUserControl: false,
		errorUserControl: false,
		errorTextUserControl: '',
		haveAErrorUserControl: false,
		refreshListUsers: false
	})
});
