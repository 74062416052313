<script setup lang="ts">
import headerRoute from './MOBILE/headers/headerRoute.vue';
import loader from './loader.vue';
import axios from 'axios';
import toggleNotificacoes from './toggleNotificacoes.vue';
import { useToast } from 'vue-toastification';
import buttonVue from './MOBILE/buttons/button.vue';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useInfosUser } from '../stores/userInfos';
import { useLogin } from '../stores/login';
const infosUser = useInfosUser();
const loginInfos = useLogin();
const { mobile, desktop } = storeToRefs(infosUser);
const { id_empresa, idUsuario, notificacoesAtivas, modalDesvioPagamento, clickedButtonDesvioPagamento, modalNotificacoes } = storeToRefs(loginInfos);
const loaderNotificacoes = ref(false);
const closeModal = () => {
  modalNotificacoes.value = false;
};
async function listaUsuarioNotificacoes() {
  notificacoesAtivas.value = [];
  loaderNotificacoes.value = true;
  const cookies = document.cookie.split(";");
  const tokenIndex = cookies.findIndex(
    (cookie) => cookie.startsWith("token_user=") || cookie.startsWith(" token_user=")
  );
  const tokenUser = cookies[tokenIndex].split("=")[1];
  await axios.post(`${import.meta.env.VITE_AUTO_ME_USUARIO}/notificacoes/listaUsuariosNotificacoes`, {
    id_usuario: idUsuario.value
  }, {
    headers: {
      Authorization: 'Bearer ' + tokenUser,
      'Content-Type': 'application/json'
    }
  }).then((res) => {
    setTimeout(() => {
      notificacoesAtivas.value = res.data.dados;

      const item = res.data.dados.find((item: any) => item.id_notificacao_possivel === 4);

      if (item && item.variavel !== null) {
        item.variavel *= 100;
        item.variavel = `${item.variavel},00%`;
      }
      loaderNotificacoes.value = false;
    }, 600);


  }).catch((err) => {
    console.log(err);
  });
}
listaUsuarioNotificacoes();
watch(modalDesvioPagamento, (val, oldVal) => {
  if (oldVal == true) {
    listaUsuarioNotificacoes();
  }
});
</script>


<template>
  <div class="modalAvaliaSolicitacaoAvalDesk" style="min-width: 550px">
    <div class="tituloModal">
      <div class="label">
        <h1>Notificacoes</h1>
      </div>
      <div class="icon" @click="closeModal()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.65685 6.65711L9.88934 9.8896L12.3137 12.314M12.3137 12.314L6.65685 17.9708M12.3137 12.314L17.9706 17.9708M12.3137 12.314L17.9706 6.65711"
            stroke="#63636E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="track400"></div>
    <div class="contentModal" style="padding: 0px 0px 24px 0px;gap: 0px;"
      :style="{ justifyContent: loaderNotificacoes ? 'center' : '', alignItems: loaderNotificacoes ? 'center' : '' }">
      <toggleNotificacoes v-if="notificacoesAtivas.length > 0" v-for="(acesso, index) in notificacoesAtivas"
        :checked="acesso.ativo" :index="index" :small="true">
        <template #acessLabel>{{ acesso.notificacao_possivel }}</template>
        <template #acessDesc
          v-if="acesso.variavel !== null">{{ `Receber quando desvio for maior ou menor que: ${acesso.variavel}` }}</template>
      </toggleNotificacoes>
      <loader v-if="loaderNotificacoes == true" />
      <div v-if="notificacoesAtivas.length == 0 && loaderNotificacoes == false"
        style="width: 100%;height: 75vh;display: flex; flex-direction: column; align-items: center; justify-content: center; padding: var(--padding-l);">
        <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M54.75 32C54.75 44.1503 44.9003 54 32.75 54C20.5997 54 10.75 44.1503 10.75 32C10.75 19.8497 20.5997 10 32.75 10C44.9003 10 54.75 19.8497 54.75 32ZM15.15 32C15.15 41.7202 23.0298 49.6 32.75 49.6C42.4702 49.6 50.35 41.7202 50.35 32C50.35 22.2798 42.4702 14.4 32.75 14.4C23.0298 14.4 15.15 22.2798 15.15 32Z"
            fill="#bebec2" />
          <path d="M23.75 32L29.75 38L41.75 26" stroke="#bebec2" stroke-width="4" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
        <h1 style="font: var(--b-r-Ag-4);color: var(--cr-c-400);">Nenhum item nesta lista</h1>
      </div>
    </div>
    <div class="buttonWrapper" v-if="notificacoesAtivas.length > 0">
      <buttonVue :type="'active'" :action="'saveChangesDesvioPagamento'" :color="'active'">
        <template #labelButton>Salvar alterações</template>
      </buttonVue>
    </div>
  </div>
</template>