import { defineStore } from 'pinia';

export const useServices = defineStore('services', {
	state: () => ({
		users: 'on',
		groupsTeams: 'on',
		avaliacao: 'on',
		integracao: 'on',
		veiculo: 'on',
		regraDestino: 'on',
		regraPrecificacao: 'on',
		avaliacoes: 'on',
		solicitarAvaliacoes: 'on',
		listaSolicitacoesPendentes: 'on',
		autoPay: 'on',
		personalizarNotificacoes: 'on', 
		relatorios: 'on',
		empresas: 'on'
	})
});
