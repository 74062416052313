import { defineStore } from 'pinia';

export const usePricing = defineStore('pricing', {
  state: () => ({
    tipoVeiculo: 0,
    veiculoTipo: 0,
    eletrico: false,
    metal: 0,
    kmMaximaRepasse: '',
    idRegraDestino: 0,
    haveAErrorRulesDestination: false,
    errorRulesDestination: false,
    errorTextRulesDestination: '',
    clickedSaveChangesDestination: false,
    clickedResetRulesDestination: false,
    tipoPrecificacao: 0,
    uf: '',
    ufId: 0,
    dropdownUfs: false,
    margemNegociacao: '',
    margemMinima: '',
    margemMaxima: '',
    lucro: '',
    lucroMinimo: '',
    lucroMaximo: '',
    higienizacaoCusto: '',
    polimentoCusto: '',
    tempoEstoqueMaximo: '',
    tempoEstoqueMinimo: '',
    acrescimoParaReparo: '',
    depreciacaoFipe: false,
    depreciacaoCapital: false,
    depreciacaoCapitalValue: '',
    carenciaFloorplan: '',
    percentualFipeCustosRevisao: '',
    aliquotaIpva: '',
    aliquotaIcms: '',
    aliquotaPisCofins: '',
    lucroRepassador: '',
    lucroRepassadorMinimo: '',
    lucroRepassadorMaximo: '',
    idRegraPrecificacao: 0,
    clickedSaveChangesPricing: false,
    clickedResetRulesPricing: false,
    haveAErrorRulesPricing: false,
    errorRulesPricing: false,
    errorTextRulesPricing: '',
    step: 0,
  }),
  actions: {
    decrementStep() {
      if (this.step > 0) {
        this.step -= 1;
      }
    }
  }
});
