import { defineStore } from 'pinia';

export const useInfosUser = defineStore('infosUser', {
	state: () => ({
		desktop: false,
		mobile: false,
		screenWidth: 0,
		screenHeight: 0,
		routeUser: '' as any
	})
});
