import { defineStore } from 'pinia';

export const vehicle = defineStore('vehicleInfos', {
	state: () => ({
		placa: '',
		placaError: false,
		placaErrorText: '',
		tipoVeiculo: '',
		idTipoVeiculo: 0 as string | number,
		tipoVeiculoIdx: null as number | null,
		renavam: '',
		renavamErrorText: '',
		renavamError: false,
		marca: '' as string,
		marcaId: 0,
		dropdownMarca: false,
		modelo: '',
		modeloId: 0,
		eletricoAval: false,
		dropdownModelo: false,
		anoModelo: '',
		id_combustivel: 0 as number | Array<number>,
		dropdownAno: false,
		versao: '',
		idVersao: 0 as string | number,
		dropdownVersao: false,
		historicosFipe: [] as any,
		anoFabricacao: '',
		anoFabricacaoError: false,
		anoFabricacaoErrorText: '',
		cor: '',
		idCor: 0,
		dropdownCores: false,
		carroceria: '',
		carroceriaId: 0,
		dropdownCarroceria: false,
		transmissao: '',
		transmissaoId: 0,
		dropdownTransmissao: false,
		combustivel: '',
		combustivelId: 0,
		dropdownCombustivel: false,
		portas: '',
		portasId: 0,
		portasDropdown: false,
		blindagem: false,
		tetoSolar: false,
		arCondicionado: false,
		id_fipe: '',
		codigo_fipe: '' as number | string,
		id_fipe_historico: '',
		veiculo_tipo: '' as any,
		conviteVerified: false,
		clickMarca: false,
		clickModelo: false,
		clickVersao: false
	})
});
