import { defineStore } from 'pinia';

export const useRelatorios = defineStore('relatorios', {
  state: () => ({
    agruparPor: '',
    agrupaPorDropdownOpen: false,
    equipeRelatorios: '',
    equipeRelatoriosDropdownOpen: false,
    grupoRelatorios: '',
    grupoRelatoriosDropdownOpen: false,
    usuarioRelatorios: '',
    usuarioRelatoriosDropdownOpen: false,
    tipoVeiculoRelatorios: '',
    tipoVeiculoRelatoriosDropdownOpen: false,
    classificacaoMetalRelatorios: '',
    classificacaoMetalRelatoriosDropdownOpen: false,
    statusRelatorios: '',
    statusRelatoriosDropdownOpen: false,
    tipoNegociacaoRelatorios: '',
    tipoNegociacaoRelatoriosDropdownOpen: false,
    tipoAvaliacaoRelatorios: '',
    tipoAvaliacaoRelatoriosDropdownOpen: false,
    incluirIntegracoesRelatorios: '',
    dateAllRelatorios: [] as any,
    dataInicialRelatorios: '',
    dataFinalRelatorios: '',
    isSeaching: false
  })
});
