<script setup lang="ts">
import { useLogin } from '../stores/login';
import { useInfosUser } from '../stores/userInfos';
import { watch, ref } from 'vue';
import { storeToRefs } from 'pinia';
const loginStore = useLogin();
const userInfos = useInfosUser();
const { errorLogin, errorText } = storeToRefs(loginStore);
const { desktop, mobile } = storeToRefs(userInfos);
import inputGroup from './inputGroup.vue';
import buttonVue from './MOBILE/buttons/button.vue';
let anoAtual = new Date().getFullYear();
const errorEmail = ref(false);
const errorPassword = ref(false);

watch(errorText, (newVal) => {
	errorEmail.value = errorLogin.value && (errorText.value == 'E-mail ou senha não correspondem' || errorText.value == 'Formato de e-mail inválido');
	errorPassword.value = errorLogin.value && (errorText.value == 'Tamanho minimo de senha 6 digítos' || errorText.value == 'E-mail ou senha não correspondem');
});
</script>

<template>
	<div class="loginContainer">
		<img src="../assets/AutomeLogoCompleta.svg" alt="Logo AutoMe Completa" width="180" />
		<div class="form">
			<inputGroup :type="'login'" :error="errorEmail" :placeholder="'usuario@autome.com.br'" :disabled="false" :border="false" v-if="mobile">
				<template #label> Login </template>
				<template #errorText>
					{{ errorText }}
				</template>
			</inputGroup>
			<inputGroup :type="'login'" :error="errorEmail" :placeholder="'usuario@autome.com.br'" :disabled="false" :border="errorEmail ? false : true" v-else>
					<template #label> Login </template>
					<template #errorText>
						{{ errorText }}
					</template>
				</inputGroup>
			<div class="error" v-if="errorEmail">
				<p>
					{{ errorText }}
				</p>
			</div>
			<inputGroup :type="'password'" :error="errorPassword" :placeholder="'********'" :disabled="false" :border="false" v-if="mobile">
				<template #label> Senha </template>
			</inputGroup>
			<inputGroup :type="'password'" :error="errorPassword" :placeholder="'********'" :disabled="false" :border="errorPassword ? false : true" v-else>
					<template #label> Senha </template>
				</inputGroup>
			<div class="error" v-if="errorPassword">
				<p>
					{{ errorText }}
				</p>
			</div>
		</div>
		<buttonVue :type="'active'" :action="'login'">
			<template #labelButton> Login </template>
		</buttonVue>
		<buttonVue :type="'empty'" :action="'forgotPassword'">
			<template #labelButton> Esqueci a senha </template>
		</buttonVue>
		<footer>
			<p>
				Ao clicar em login você aceita o armazenamento de cookies para melhor utilização do aplicativo. <br />
				AutoMe {{ anoAtual }}
			</p>
		</footer>
	</div>
</template>
