<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import buttonVue from './MOBILE/buttons/button.vue';
import { useInfosUser } from '../stores/userInfos';
import { OnClickOutside } from '@vueuse/components';
import { storeToRefs } from 'pinia';

//@ts-ignore
import { useRegisterSW } from 'virtual:pwa-register/vue'

const infosUser = useInfosUser();
const { mobile, desktop } = storeToRefs(infosUser);


const period = 5 * 60 * 1000;

const swActivated = ref(false);

async function registerPeriodicSync(swUrl: string, r: ServiceWorkerRegistration) {
  if (period <= 0) return;

  setInterval(async () => {
    if ('onLine' in navigator && !navigator.onLine)
      return;

    const resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        'cache': 'no-store',
        'cache-control': 'no-cache',
      },
    });

    if (resp?.status === 200)
      await r.update();
  }, period);
}

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegisteredSW(swUrl: any, r: any) {
    if (period <= 0) return;
    if (r?.active?.state === 'activated') {
      swActivated.value = true;
      registerPeriodicSync(swUrl, r);
    }
    else if (r?.installing) {
      r.installing.addEventListener('statechange', (e: any) => {
        const sw = e.target as ServiceWorker;
        swActivated.value = sw.state === 'activated';
        if (swActivated.value)
          registerPeriodicSync(swUrl, r);
      });
    }
  },
});

const title = computed(() => {
  if (needRefresh)
    return 'Nova atualização detectada';
  return '';
});
watch(needRefresh, (val) => {
  const appElement = document.getElementById('app');
  if (val == true) {
    if (appElement) {
      appElement.style.overflow = 'hidden';
    } else {
      console.error('Elemento com ID "app" não encontrado');
    }
  } else {
    if (appElement) {
      appElement.style.overflow = 'auto';
    } else {
      console.error('Elemento com ID "app" não encontrado');
    }
  }
});
function close() {
  offlineReady.value = false;
  needRefresh.value = false;
};
</script>

<template>
  <TransitionGroup name="modalEnter">
    <div class="bg" v-if="needRefresh">
      <OnClickOutside @trigger="close()" class="clickOutside" v-if="mobile">
        <div class="modalEditUser">
          <div class="body">
            <div class="titulo">
              <div class="label">
                <h1>{{ title }}</h1>
              </div>
              <div class="icon" @click="close">
                <img src="/src/assets/icons/x.svg" />
              </div>
            </div>
            <div class="inputWrapper" style="padding: 0px var(--padding-xl);">
              Uma nova versão do sistema está disponível para atualização.
            </div>
            <div class="content">
              <buttonVue :action="'none'" :type="'custom'" :color="'active'" @click="updateServiceWorker()">
                <template #labelButton>Atualizar</template>
              </buttonVue>
            </div>
          </div>
        </div>
      </OnClickOutside>
      <div class="modalAddTeamDesktop" v-if="desktop">
        <div class="tituloModalAddTeamDesktop">
          <div class="label">
            <h1>{{ title }}</h1>
          </div>
          <div class="iconRight">
            <svg @click="close()" width="24" height="24" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.65685 6.65687L9.88934 9.88935L12.3137 12.3137M12.3137 12.3137L6.65685 17.9706M12.3137 12.3137L17.9706 17.9706M12.3137 12.3137L17.9706 6.65687"
                stroke="#121F40" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
        </div>
        <div class="track400"></div>
        <div class="contentAddTeamDesktop">
          <div class="inputWrapperAddTeamDesktop" style="overflow: auto;">
            Uma nova versão do sistema está disponível para atualização.
          </div>
          <div class="botao">
            <buttonVue :action="'none'" :type="'custom'" :color="'active'" @click="updateServiceWorker()">
              <template #labelButton>Atualizar</template>
            </buttonVue>
          </div>
        </div>
      </div>
    </div>
  </TransitionGroup>
</template>