<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useInfosUser } from '../stores/userInfos';
const infosUser = useInfosUser();
const { mobile, desktop } = storeToRefs(infosUser);
import loginSenha from '../components/loginSenha.vue';
import splash from '../components/splash.vue';
</script>

<template>
	<main>
		<splash v-if="mobile" />
		<loginSenha />
	</main>
</template>
